


import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import Plane from "./Plane";

import { tripBuilderGetAndInflate, tripBuilderInflate } from "~/helpers/waypoints.js";

function getDirection(lat1, lon1, lat2, lon2) {
  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  if (Math.abs(dLat) > Math.abs(dLon)) {
    return dLat > 0 ? Math.sin(1 * Math.PI / 4) : Math.sin(2 * Math.PI / -4);
  } else {
    return dLon > 0 ? Math.sin(3 * Math.PI / -4) : Math.sin(4 * Math.PI / 4);
  }
}


function getDistance(lat1, lon1, lat2, lon2) {
  // Radius of the Earth in kilometers
  const R = 6371;

  // Convert degrees to radians
  const toRad = (value) => value * Math.PI / 180;

  // Difference in coordinates
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);

  // Convert latitude to radians
  const radLat1 = toRad(lat1);
  const radLat2 = toRad(lat2);

  // Haversine formula
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(radLat1) * Math.cos(radLat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  const distance = R * c;

  // Circumference of the Earth in kilometers
  const earthCircumference = 2 * Math.PI * R;

  // Half the circumference of the Earth
  const halfCircumference = earthCircumference / 4;

  // Calculate the percentage of the distance relative to half the Earth's circumference
  const percentage = distance / halfCircumference;

  // Map the percentage to a scale of 1 to 10
  const scale = Math.max(Math.round(percentage * 1000), 1)

  return scale / 20;
}



function Map({ loaderData, status, airports }) {
  const mapContainer = useRef(null);
  const [originAirport, setOriginAirport] = useState({ "meta": { "lat": 51.5072, "long": -0.1276 } });
  const [animated, setAnimated] = useState(false);
  const [direction, setDirection] = useState(Math.sin(1 * Math.PI / 4));
  const [distance, setDistance] = useState(5);
  const [showcase, setShowcase] = useState(false);

  const [destinationAirport, setDestinationAirport] = useState({ "meta": { "lat": 45.5072, "long": 15.1276 } });




  let renderMap = async () => {
    if (typeof loaderData.trip_builder !== "undefined" && loaderData.trip_builder !== null) {
      let inflated = await tripBuilderGetAndInflate();
      inflated = tripBuilderInflate(inflated);

      let trip_loader = inflated
      try {
        let parsed = JSON.parse(loaderData.trip_builder);
        if (parsed.length) {
          trip_loader = parsed
        }


      } catch (e) {
        if (loaderData.trip_builder.length) {
          trip_loader = loaderData.trip_builder;
        }

      }



      let origin = "LGW"
      let destination = "JFK"
      console.log("AFSD", trip_loader)
      if (trip_loader?.length > 1) {
        origin = trip_loader[0]?.originFlight?.origin;
        destination = trip_loader[0]?.originFlight?.destination;
      }

      if (airports.length) {
        let airport = airports.find(airport => airport.code === origin);
        setOriginAirport(airport);
        setDestinationAirport(airport);
      }

      mapboxgl.accessToken = loaderData.accessToken;

      let distanceDivider = 60
      let baseSpeedMultiplier = 40000


      let steps = [...Array(9)].map((_, i) => i + 1);
      let zooms = [15, 15, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5];

      let speeds = [];

      trip_loader.forEach((trip) => {
        if (typeof trip !== "undefined" && typeof trip?.originFlight?.origin !== "undefined") {
          let origin = airports.find((airport) => airport.code === trip.originFlight.origin);
          let destination = airports.find((airport) => airport.code === trip.destinationFlight.destination);
          let distance = getDistance(origin.meta.lat, origin.meta.long, destination.meta.lat, destination.meta.long);
          speeds.push(distance / distanceDivider);

          if (trip.middleFlight1 && trip.middleFlight1.origin && trip.middleFlight1.destination) {
            let middle1Origin = airports.find((airport) => airport.code === trip.middleFlight1.origin);
            let middle1Destination = airports.find((airport) => airport.code === trip.middleFlight1.destination);
            let middle1Distance = getDistance(middle1Origin.meta.lat, middle1Origin.meta.long, middle1Destination.meta.lat, middle1Destination.meta.long);
            speeds.push(middle1Distance / distanceDivider);
          }

          if (trip.middleFlight2 && trip.middleFlight2.origin && trip.middleFlight2.destination) {
            let middle2Origin = airports.find((airport) => airport.code === trip.middleFlight2.origin);
            let middle2Destination = airports.find((airport) => airport.code === trip.middleFlight2.destination);
            let middle2Distance = getDistance(middle2Origin.meta.lat, middle2Origin.meta.long, middle2Destination.meta.lat, middle2Destination.meta.long);
            speeds.push(middle2Distance / distanceDivider);
          }
        }
        else {
          speeds = Array.from({ length: 9 }, (_, i) => 0.6 + (i < 5 ? i * 0.5 : (4 - i % 5) * 0.5));
        }

      });

      if (speeds.length === 4) {
        speeds = [...speeds, ...speeds, speeds[0]];
      } else if (speeds.length === 2) {
        speeds = Array(4).fill(speeds).flat();
        speeds.push(speeds[0]);
      } else if (speeds.length === 3) {
        speeds = [...speeds, ...speeds, speeds[0], speeds[1], speeds[2]];
      }

      console.log('speeds BEFORE', speeds)
      //speeds = Array.from({ length: 9 }, (_, i) => 0.6 + (i < 5 ? i * 0.5 : (4 - i % 5) * 0.5));

      let pitch = 90;


      let totalDistance = speeds.reduce((acc, distance) => acc + distance, 0);
      let baseSpeed = totalDistance / speeds.length * baseSpeedMultiplier;
      console.log("SPEED", baseSpeed);
      console.log('speeds', speeds)

      let easing = t => t * t * (3 - 2 * t)
      let past = {}
      let temp_direction = 1



      if (typeof mapboxgl.accessToken === 'string') {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/nicklz/clvizy0em00bf01obhif4acye', // Dark theme
          interactive: false,
        });



        // Listen for the 'movestart' event to detect when the map starts moving
        map.on('movestart', () => {
          setAnimated(true);
        });

        // Listen for the 'moveend' event to detect when the map stops moving
        map.on('moveend', () => {
          setAnimated(false);
        });


        // Update map's center when originAirport changes
        map.on('load', () => {
          setAnimated(true)
          if (typeof trip_loader[0]?.originFlight?.origin !== "undefined") {
            let origin = airports.find(airport => airport.code === trip_loader[0].originFlight.origin);
            let destination = airports.find(airport => airport.code === trip_loader[0].originFlight.destination);
            let direction = getDirection(origin.meta.lat, origin.meta.long, destination.meta.lat, destination.meta.long)
            let distance = getDistance(origin.meta.lat, origin.meta.long, destination.meta.lat, destination.meta.long)
            setDistance(distance)
            setDirection(direction)



            map.setCenter([origin.meta.long, origin.meta.lat]);
            setTimeout(function () {
              console.log("animated", animated)
              console.log("FLY_TO 0", {
                center: [destination.meta.long, destination.meta.lat],
                zoom: zooms[1],
                pitch: pitch,
                speed: speeds[1], // Controls the speed of the flight
                curve: 1,   // Controls the curvature of the flight path
                easing: t => t, // Easing function for the flight path
              })

              map.flyTo({
                center: [destination.meta.long, destination.meta.lat],
                zoom: zooms[1],
                pitch: pitch,
                speed: speeds[1], // Controls the speed of the flight
                curve: 1,   // Controls the curvature of the flight path
                easing: t => t, // Easing function for the flight path
              });

              if (typeof trip_loader[0].middleFlight1.origin !== "undefined" && trip_loader[0].middleFlight1.origin !== undefined) {


                setTimeout(function () {
                  console.log("MIDDLEFLIGHTTRIGGERED 11 ")
                  past = Object.assign({}, destination);
                  destination = airports.find(airport => airport.code === trip_loader[0].middleFlight1.origin);
                  temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  setDistance(distance)
                  setDirection(temp_direction);




                  console.log("FLY_TO 1", {
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[2],
                    pitch: pitch,
                    speed: speeds[2], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t
                  })



                  map.flyTo({
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[2],
                    pitch: pitch,
                    speed: speeds[2], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t, // Easing function for the flight path
                  });
                  past = Object.assign({}, destination);
                  destination = airports.find(airport => airport.code === trip_loader[0].middleFlight1.destination);
                  temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  setDistance(distance)
                  setDirection(temp_direction);

                  console.log("FLY_TO 2", {
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[2],
                    pitch: pitch,
                    speed: speeds[3], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t, // Ea
                  })
                  map.flyTo({
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[2],
                    pitch: pitch,
                    speed: speeds[3], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t, // Easing function for the flight path
                  });

                }, (baseSpeed * steps[1])); // 1000 milliseconds = 1 second

                setTimeout(function () {
                  past = Object.assign({}, destination);
                  destination = airports.find(airport => airport.code === trip_loader[0].middleFlight1.origin);
                  temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  setDistance(distance)
                  setDirection(temp_direction);
                  console.log("FLY_TO 3", {
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[3],
                    pitch: pitch,
                    speed: speeds[3], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t,
                  })
                  map.flyTo({
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[3],
                    pitch: pitch,
                    speed: speeds[3], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t, // Easing function for the flight path
                  });
                  past = Object.assign({}, destination);
                  destination = airports.find(airport => airport.code === trip_loader[0].middleFlight1.destination);
                  temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  setDistance(distance)
                  setDirection(temp_direction);
                  console.log("FLY_TO 4", {
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[4],
                    pitch: pitch,
                    speed: speeds[4], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t,
                  })
                  map.flyTo({
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[4],
                    pitch: pitch,
                    speed: speeds[4], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t, // Easing function for the flight path
                  });

                }, (baseSpeed * steps[1])); // 1000 milliseconds = 1 second
              }
              else {
                steps[2] -= 1
                steps[3] -= 1
                steps[4] -= 1
                steps[5] -= 1
                steps[6] -= 1
                steps[7] -= 1
                steps[8] -= 1
                steps[9] -= 1
              }

              // Wait for 1 second
              if (typeof trip_loader[0].middleFlight2.origin !== "undefined" && trip_loader[0].middleFlight2.origin !== undefined) {
                setTimeout(function () {
                  past = Object.assign({}, destination);
                  destination = airports.find(airport => airport.code === trip_loader[0].middleFlight1.origin);
                  temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  setDistance(distance)
                  setDirection(temp_direction);
                  console.log("FLY_TO 5", {
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[5],
                    pitch: pitch,
                    speed: speeds[5], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t,
                  })
                  map.flyTo({
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[5],
                    pitch: pitch,
                    speed: speeds[5], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t, // Easing function for the flight path
                  });
                  past = Object.assign({}, destination);
                  destination = airports.find(airport => airport.code === trip_loader[0].middleFlight1.destination);
                  temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  setDistance(distance)
                  setDirection(temp_direction);
                  console.log("FLY_TO 6", {
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[6],
                    pitch: pitch,
                    speed: speeds[6], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t,
                  })
                  map.flyTo({
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[6],
                    pitch: pitch,
                    speed: speeds[6], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t, // Easing function for the flight path
                  });

                }, (baseSpeed * steps[2])); // 1000 milliseconds = 1 second
              }
              else {
                steps[4] -= 1
                steps[5] -= 1
                steps[6] -= 1
                steps[7] -= 1
                steps[8] -= 1
                steps[9] -= 1
              }



              past = Object.assign({}, destination);
              destination = airports.find(airport => airport.code === trip_loader[0].destinationFlight.origin);
              temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
              distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
              setDistance(distance)
              setDirection(temp_direction);

              setTimeout(function () {
                console.log("FLY_TO 7", {
                  center: [destination.meta.long, destination.meta.lat],
                  zoom: zooms[7],
                  pitch: pitch,
                  speed: speeds[7], // Controls the speed of the flight
                  curve: 1,   // Controls the curvature of the flight path
                  easing: t => t,
                })
                map.flyTo({
                  center: [destination.meta.long, destination.meta.lat],
                  zoom: zooms[7],
                  pitch: pitch,
                  speed: speeds[7], // Controls the speed of the flight
                  curve: 1,   // Controls the curvature of the flight path
                  easing: t => t, // Easing function for the flight path
                });
              }, (baseSpeed * steps[3])); // 1000 milliseconds = 1 second

              // Wait for 1 second
              setTimeout(function () {
                past = Object.assign({}, destination);
                destination = airports.find(airport => airport.code === trip_loader[0].destinationFlight.destination);
                temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                setDistance(distance)
                setDirection(temp_direction);
                console.log("FLY_TO 8", {
                  center: [destination.meta.long, destination.meta.lat],
                  zoom: zooms[2],
                  pitch: pitch,
                  speed: speeds[0], // Controls the speed of the flight
                  curve: 1,   // Controls the curvature of the flight path
                  easing: t => t,
                })
                map.flyTo({
                  center: [destination.meta.long, destination.meta.lat],
                  zoom: zooms[2],
                  pitch: pitch,
                  speed: speeds[0], // Controls the speed of the flight
                  curve: 1,   // Controls the curvature of the flight path
                  easing: t => t, // Easing function for the flight path
                });

              }, (baseSpeed * steps[4])); // 1000 milliseconds = 1 second

              // THEN DO IT AGAIN FOR THE SECOND ROUTE

              if (trip_loader[1]?.originFlight?.destination !== "undefined" && trip_loader?.length > 1) {
                past = Object.assign({}, destination);
                destination = airports.find(airport => airport.code === trip_loader[1].originFlight.destination);
                temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                setDistance(distance)
                setDirection(temp_direction);

                // Wait for 1 second
                setTimeout(function () {
                  console.log("FLY_TO 9", {
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[0],
                    pitch: pitch,
                    speed: speeds[0], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t,
                  })
                  map.flyTo({
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[9],
                    pitch: pitch,
                    speed: speeds[0], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: t => t, // Easing function for the flight path
                  });

                }, (baseSpeed * steps[5])); // 1000 milliseconds = 1 second

                // Wait for 1 second

                if (typeof trip_loader[1].middleFlight1.origin !== "undefined" && trip_loader[1].middleFlight1.origin !== undefined) {

                  setTimeout(function () {
                    past = Object.assign({}, destination);
                    destination = airports.find(airport => airport.code === trip_loader[1].middleFlight1.origin);
                    temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                    distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                    setDistance(distance)
                    setDirection(temp_direction);
                    map.flyTo({
                      center: [destination.meta.long, destination.meta.lat],
                      zoom: zooms[0],
                      pitch: pitch,
                      speed: speeds[0], // Controls the speed of the flight
                      curve: 1,   // Controls the curvature of the flight path
                      easing: t => t, // Easing function for the flight path
                    });
                    past = Object.assign({}, destination);
                    destination = airports.find(airport => airport.code === trip_loader[1].middleFlight1.destination);
                    temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                    distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                    setDistance(distance)
                    setDirection(temp_direction);

                    map.flyTo({
                      center: [destination.meta.long, destination.meta.lat],
                      zoom: zooms[0],
                      pitch: pitch,
                      speed: speeds[0], // Controls the speed of the flight
                      curve: 1,   // Controls the curvature of the flight path
                      easing: t => t, // Easing function for the flight path
                    });

                  }, (baseSpeed * steps[6])); // 1000 milliseconds = 1 second
                }
                else {
                  steps[7] -= 1;
                  steps[8] -= 1;
                  steps[9] -= 1
                }


                // Wait for 1 second
                if (typeof trip_loader[1].middleFlight2.origin !== "undefined" && trip_loader[1].middleFlight2.origin !== undefined) {

                  setTimeout(function () {
                    past = Object.assign({}, destination);
                    destination = airports.find(airport => airport.code === trip_loader[1].middleFlight1.origin);
                    temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                    distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                    setDistance(distance)
                    setDirection(temp_direction);

                    map.flyTo({
                      center: [destination.meta.long, destination.meta.lat],
                      zoom: zooms[6],
                      pitch: pitch,
                      speed: speeds[0], // Controls the speed of the flight
                      curve: 1,   // Controls the curvature of the flight path
                      easing: t => t, // Easing function for the flight path
                    });
                    past = Object.assign({}, destination);
                    destination = airports.find(airport => airport.code === trip_loader[1].middleFlight1.destination);
                    temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                    distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                    setDistance(distance)
                    setDirection(temp_direction);

                    map.flyTo({
                      center: [destination.meta.long, destination.meta.lat],
                      zoom: zooms[5],
                      pitch: pitch,
                      speed: speeds[0], // Controls the speed of the flight
                      curve: 1,   // Controls the curvature of the flight path
                      easing: t => t, // Easing function for the flight path
                    });

                  }, (baseSpeed * steps[7])); // 1000 milliseconds = 1 second

                  // Wait for 1 second
                  setTimeout(function () {
                    past = Object.assign({}, destination);
                    destination = airports.find(airport => airport.code === trip_loader[1].middleFlight1.origin);
                    temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                    distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                    setDistance(distance)
                    setDirection(temp_direction);

                    map.flyTo({
                      center: [destination.meta.long, destination.meta.lat],
                      zoom: zooms[4],
                      pitch: pitch,
                      speed: speeds[0], // Controls the speed of the flight
                      curve: 1,   // Controls the curvature of the flight path
                      easing: t => t, // Easing function for the flight path
                    });

                  }, (baseSpeed * steps[8])); // 1000 milliseconds = 1 second

                }
                else {
                  steps[8] -= 1
                }

                // Wait for 1 second
                setTimeout(function () {

                  steps[8] += 1

                  past = Object.assign({}, destination);
                  destination = airports.find(airport => airport.code === trip_loader[1].destinationFlight.destination);
                  temp_direction = getDirection(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  distance = getDistance(past.meta.lat, past.meta.long, destination.meta.lat, destination.meta.long)
                  setDistance(distance)
                  setDirection(temp_direction);

                  map.flyTo({
                    center: [destination.meta.long, destination.meta.lat],
                    zoom: zooms[8],
                    pitch: pitch,
                    speed: speeds[0], // Controls the speed of the flight
                    curve: 1,   // Controls the curvature of the flight path
                    easing: easing, // Easing function for the flight path
                  });
                }, (baseSpeed * steps[8])); // 1000 milliseconds = 1 second
              }
            }, (0));
          }
          else {
            console.log("NO TRIP FOUND")
            map.setCenter([-118.4036, 33.9422]);
            map.setZoom(13);
            map.setPitch(pitch);
            setShowcase(true)
            console.log(showcase)
            setAnimated(false)
          }
        });
      }
    }
  }
  useEffect(() => {
    renderMap()

  }, []);



  return (
    <>
      <Plane originAirport="LAX" destinationAirport="JFK" animated={animated} direction={direction} distance={distance} showcase={showcase}></Plane>
      <div className="map-wrapper fade-in-very-slow">
        <div ref={mapContainer} style={{ height: '400px', backgroundColor: '#111' }} />
      </div>
    </>

  );
}

export default Map;
