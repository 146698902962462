export const initialModals = [
  {
    title: "Number of Waypoints",
    content: (
      <p>
        Choose the number of cities you'd like to visit between your
        starting point and final destination. Selecting more cities can
        potentially reduce your overall travel costs. Please note that
        this feature is available to PRO users only{" "}
        <span role="img" aria-label="PRO feature">
          🔒
        </span>
        . To access it, please register an account and
        <div className="needs-login">
          <button className="login-trip-button yellow" onClick={() => {
            document.querySelector('.login').click()
          }}>Please Log in and Active PRO</button>
        </div>
      </p>
    ),
    isOpen: false,
  },
  {
    title: "Exclude Waypoints",
    content: (
      <p>
        Exclude cities you'd prefer not to visit between your starting
        point and final destination. Please note that
        this feature is available to PRO users only{" "}
        <span role="img" aria-label="PRO feature">
          🔒
        </span>
        . To access it, please register an account and
        <div className="needs-login">
          <button className="login-trip-button yellow" onClick={() => {
            document.querySelector('.login').click()
          }}>Please Log in and Active PRO</button>
        </div>
      </p>
    ),
    isOpen: false,
  },
  {
    title: "Quality",
    content: (
      <p>
        Quality enhancement optimizes departure times and prevents
        revisiting the same city. Please note that
        this feature is available to PRO users only{" "}
        <span role="img" aria-label="PRO feature">
          🔒
        </span>
        . To access it, please register an account and
        <div className="needs-login">
          <button className="login-trip-button yellow" onClick={() => {
            document.querySelector('.login').click()
          }}>Please Log in and Active PRO</button>
        </div>
      </p>
    ),
    isOpen: false,
  },
  {
    title: "Number of days at each waypoint.",
    content: (
      <p>
        This indicates the duration of stay at each Waypoint, reflecting the number of days between arrival and departure for the next flight. For those who prefer a more relaxed pace, opting for a longer duration is advisable.<br />
        <br />
        ⚠️ Caution: Utilizing zero days is classified as "Skip Lagging" and is discouraged. This is primarily due to potential time constraints in navigating through security checks. ⚠️
      </p>
    ),
    isOpen: false,
  },
];
