import React from "react";
import { Dialog } from "@headlessui/react";

export default function Modal({ isOpen, onClose, title, content }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 flex items-center justify-center z-10 modal"
    >
      {/* Overlay */}
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

      {/* Modal */}
      <div className="bg-white w-full max-w-md p-6 my-8 mx-auto text-center align-middle transition-all transform shadow-xl rounded-lg modal-custom pulse">
        <h2 className="mb-4 text-xl font-semibold">{title}</h2>

        {/* Modal Content */}
        <div className="modal-content">{content}</div>

        {/* Modal Actions */}
        <div className="mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 mr-2 text-sm font-medium text-gray-500 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 focus-visible:ring-blue-400"
          >
            Close
          </button>
        </div>
      </div>
    </Dialog >
  );
}
