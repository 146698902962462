import { useEffect, useRef, useState } from "react";
import { Form, useFetcher, useTransition } from "@remix-run/react";
import { initialModals } from "../../helpers/modals";
import Field from "./Field";
import Map from "./Map";
import Select from "./Select";
import Button from "./Button";
import { Trip, TripCardWrapper } from "./Trip";
import { tripBuilderGetAndInflate, tripBuilderInflate } from "~/helpers/waypoints.js";
import { log } from "~/helpers/log";

const { v4: uuidv4 } = require('uuid');


import { tripBuilderDelete } from "../../helpers/waypoints.js";


export default function TripBuilder({ loaderData, type }) {
  const [trips, setTrips] = useState([]);

  useEffect(() => {



    const initializeTrips = () => {
      let initialTrips = [];
      let inflated = tripBuilderGetAndInflate();



      if (
        typeof loaderData?.session?.data?.user?.data?.meta?.trip_builder !==
        "undefined"
      ) {
        initialTrips = JSON.parse(
          loaderData.session.data.user.data.meta.trip_builder
        );

      } else if (
        typeof loaderData?.trip_builder !== "undefined" &&
        loaderData?.trip_builder?.length
      ) {
        initialTrips = loaderData.trip_builder;

      } else if (
        typeof loaderData?.shared !== "undefined" &&
        typeof loaderData?.shared?.data !== "undefined"
      ) {
        initialTrips = loaderData?.shared.data.data.trip_builder;

      } else if (inflated?.length) {
        initialTrips = inflated;

      }


      initialTrips = tripBuilderInflate(initialTrips);

      setTrips(initialTrips);
    };

    initializeTrips();

  }, [loaderData]);





  if (loaderData.days === undefined || loaderData.days === null) {
    loaderData.days = 2;
  }

  if (loaderData.count === undefined || loaderData.count === null) {
    loaderData.count = 1;
  }

  if (loaderData.page === undefined || loaderData.page === null) {
    loaderData.page = 1;
  }
  if (loaderData.pageMax === undefined || loaderData.pageMax === null) {
    loaderData.pageMax = 1;
  }

  const transition = useTransition();
  const notifyFormRef = useRef();
  const notifyFetcher = useFetcher();
  const status = loaderData?.status;

  // Console log the value of the cookie
  const isBusy = transition.state !== "idle" || notifyFetcher.state !== "idle";

  useEffect(function () {
    if (notifyFetcher.type === "done" && notifyFetcher.data?.result) {
      //notifyFormRef.current.reset();
    }
  }, [notifyFetcher]);

  useEffect(
    function () {
      notifyFetcher.data = {};
    },
    [notifyFetcher, loaderData?.query]
  );



  const handleClear = () => {
    setTrips([]);
    tripBuilderDelete();
  };


  const [value, setValue] = useState({
    startDate: loaderData.date,
  });



  loaderData.date = value.startDate;


  let queryOrigin = loaderData?.origin;
  let queryDestination = loaderData?.destination;
  let queryDate = loaderData?.date;

  loaderData.query = JSON.stringify({
    queryOrigin,
    queryDestination,
    queryDate,
  });


  return (
    <>
      {isBusy && (
        <div className="loading"><span className="loader"></span></div>
      )}


      <Map loaderData={loaderData} status={status} airports={loaderData.airportsAll} />
      {typeof loaderData?.trip_builder[0]?.waypoint !== 'undefined' ? (
        <>
          <div className="cost-savings-wrapper ">
            <div className="trip1">
              <h3 className="font-bold text-2xl bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 text-transparent content">{loaderData.trip_builder[0].waypoint.route}</h3>
              <h3 className="font-bold text-2xl bg-clip-text"><span>{loaderData.trip_builder[0].originFlight.date}</span></h3>

              <h3 className="cost-savings waypoints-cost total-cost">Waypoints Cost: <span className="total-cost">${loaderData.trip_builder[0].waypoint.price}.00</span></h3>
              <h3 className="cost-savings regular-cost total-cost">Regular Cost: <span className="total-cost">${loaderData.trip_builder[0].waypoint.direct}.00</span></h3>
              <h3 className="cost-savings savings total-cost">Savings: <span className="total-cost">${loaderData.trip_builder[0].waypoint.direct - loaderData.trip_builder[0].waypoint.price}.00</span></h3>
            </div>
            <div className="trip2">
              <h3 className="font-bold text-2xl bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 text-transparent content">{loaderData.trip_builder[1].waypoint.route}</h3>
              <h3 className="font-bold text-2xl bg-clip-text"><span>{loaderData.trip_builder[1].originFlight.date}</span></h3>

              <h3 className="cost-savings waypoints-cost total-cost">Waypoints Cost: <span className="total-cost">${loaderData.trip_builder[1].waypoint.price}.00</span></h3>
              <h3 className="cost-savings regular-cost total-cost">Regular Cost: <span className="total-cost">${loaderData.trip_builder[1].waypoint.direct}.00</span></h3>
              <h3 className="cost-savings  savings total-cost">Savings: <span className="total-cost">${loaderData.trip_builder[1].waypoint.direct - loaderData.trip_builder[1].waypoint.price}.00</span></h3>
            </div>

          </div>
        </>
      ) : null}
      <div
        id="trip-builder-form"

        className="fade-in flex flex-col items-stretch justify-start gap-2"
      >
        <div className="menu bg-white w-full max-w-md p-6 my-8 mx-auto text-center align-middle transition-all transform shadow-xl rounded-lg modal-custom pulse">
          <div className="top inner">
            <div className="title desktop-only">
              <h1>Manage Trip</h1>
            </div>
          </div>
          <div className="bottom inner">
            <div className="actions">
              {status !== 2 ? (
                <>
                  <button className="yellow font-medium text-sm text-white border border-opacity-50 px-3 py-1 rounded-md block border-blue-400 bg-blue-500 hover:border-blue-300 disabled:cursor-not-allowed disabled:opacity-60 disabled:border-transparent disabled:hover:border-transparent focus:outline-none focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-blue-500 clear-trip button-regular  font-medium text-sm text-white border border-opacity-50 px-3 py-1 rounded-md block border-grey-400 bg-grey-500 hover:border-grey-300 disabled:cursor-not-allowed disabled:opacity-60 disabled:border-transparent disabled:hover:border-transparent focus:outline-none focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-blue-500 flex flex-row items-end justify-center gap-2" onClick={() => {
                    document.querySelector('.login').click()
                  }}>Log in</button>
                </>
              ) : null}
              {status !== 2 || trips.length < 2 ? (
                <>
                  <Button
                    as="a"
                    href={`/`}
                    className="button-regular  font-medium text-sm text-white border border-opacity-50 px-3 py-1 rounded-md block border-grey-400 bg-grey-500 hover:border-grey-300 disabled:cursor-not-allowed disabled:opacity-60 disabled:border-transparent disabled:hover:border-transparent focus:outline-none focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-blue-500 flex flex-row items-end justify-center gap-2"
                  >
                    Add Trip
                  </Button>

                </>
              ) : null}

              {trips.length > 0 ? (
                <>
                  <Button
                    as="a"
                    href={`/share?trip_builder=${JSON.stringify(trips)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="share-trip button-regular  font-medium text-sm text-white border border-opacity-50 px-3 py-1 rounded-md block border-grey-400 bg-grey-500 hover:border-grey-300 disabled:cursor-not-allowed disabled:opacity-60 disabled:border-transparent disabled:hover:border-transparent focus:outline-none focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-blue-500 flex flex-row items-end justify-center gap-2"
                  >
                    Share Trip
                  </Button>
                  <Button
                    onClick={handleClear}
                    className="clear-trip button-regular  font-medium text-sm text-white border border-opacity-50 px-3 py-1 rounded-md block border-grey-400 bg-grey-500 hover:border-grey-300 disabled:cursor-not-allowed disabled:opacity-60 disabled:border-transparent disabled:hover:border-transparent focus:outline-none focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-blue-500 flex flex-row items-end justify-center gap-2"
                  >
                    Clear Trip
                  </Button>
                </>
              ) : null}


            </div>
          </div>
        </div>
        <TripCardWrapper>
          {loaderData?.error ? (
            <p className="text-center text-red-400 py-6">{loaderData?.error}</p>
          ) : null}
          {loaderData?.session?.data?.user?.data?.meta?.length === 0 ? (
            <div className="py-6 flex flex-col items-stretch justify-start gap-4">
              <p className="text-center">
                No trips found.
              </p>

              {notifyFetcher.state === "idle" ? (
                <>
                  {notifyFetcher.data?.result ? (
                    <p className="text-center text-green-400">
                      {notifyFetcher.data.result}
                    </p>
                  ) : null}

                  {notifyFetcher.data?.error ? (
                    <p className="text-center text-red-400">
                      {notifyFetcher.data.error}
                    </p>
                  ) : null}
                </>
              ) : (
                <p className="text-center">Wait...</p>
              )}
            </div>
          ) : null}

          {trips?.length && trips instanceof Array ? (
            trips.map(function (result) {
              if (result?.waypoint?.route) {
                return <Trip trip={result} status={status} airports={loaderData.airportsAll} key={uuidv4()} />;
              }

            })
          ) : null}

        </TripCardWrapper>
        {document.querySelector('.login') ? (
          <>
            <h1 className="needs-login">
              <Button className="login-trip-button" onClick={() => {
                document.querySelector('.login').click()
              }}>Please log in to retain trip data</Button>
            </h1>
          </>
        ) : null}


      </div>
    </>
  );
}
