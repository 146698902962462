import { useEffect, useRef, useState } from "react";
import { Form, useFetcher, useTransition } from "@remix-run/react";
import Field from "../ui/Field";
import Slider from "../ui/Slider";
import Button from "../ui/Button";
import Search from "../ui/Search";
import TripBuilder from "../ui/TripBuilder";
const { v4: uuidv4 } = require('uuid');
import { Flight, FlightCardWrapper } from "../ui/Flight";

export default function Home({ loaderData, type }) {
  // Initialize loaderData properties
  loaderData.days = loaderData.days ?? 2;
  loaderData.count = loaderData.count ?? 1;
  loaderData.page = loaderData.page ?? 1;
  loaderData.pageMax = loaderData.pageMax ?? 1;

  const transition = useTransition();
  const notifyFormRef = useRef();
  const notifyFetcher = useFetcher();
  const [isBusy, setBusy] = useState(false);




  const [hideFlightCard, setHideFlightCard] = useState(false); // State to hide/show FlightCardWrapper

  useEffect(() => {
    // Check hash value on page load
    const hash = window.location.hash;
    if (hash === "#trip-builder") {
      setHideFlightCard(true); // Set hideFlightCard to true if hash is "trip_builder"
    }
  }, []);

  useEffect(() => {
    setBusy(transition.state !== "idle" || notifyFetcher.state !== "idle");
  }, [transition.state, notifyFetcher.state]);

  useEffect(() => {
    if (notifyFetcher.type === "done" && notifyFetcher.data?.result) {
      //notifyFormRef.current.reset();
    }
  }, [notifyFetcher]);

  useEffect(() => {
    notifyFetcher.data = {};
  }, [notifyFetcher, loaderData?.query]);

  const [value, setValue] = useState({ startDate: loaderData.date });
  const [sliderValue, setSliderValue] = useState(loaderData.page);
  const [sliderValue2, setSliderValue2] = useState(loaderData.page2);

  loaderData.date = value.startDate;

  const handlePageSliderChange = (event) => {
    const searchButton = document.getElementById('search');
    searchButton.click();
  };

  const handlePageButton = (increment) => {
    const newValue = parseInt(sliderValue) + parseInt(increment);
    const newSliderValue = Math.max(1, Math.min(newValue, loaderData.pageMax));
    setSliderValue(newSliderValue);
    loaderData.page = newSliderValue;
    window.scrollTo(0, 750);

  };


  const handlePageButton2 = (increment) => {
    const newValue2 = parseInt(sliderValue2) + parseInt(increment);
    const newSliderValue2 = Math.max(1, Math.min(newValue2, loaderData.pageMax2));
    setSliderValue2(newSliderValue2);
    loaderData.page2 = newSliderValue2;
    window.scrollTo(0, 750);
    setTimeout(() => {
      // Reload the page after 1 second
      window.location.reload();
    }, 1000); // 1000 milliseconds = 1 second
  };

  let queryOrigin = loaderData?.origin;
  let queryDestination = loaderData?.destination;
  let queryDate = loaderData?.date;

  loaderData.query = JSON.stringify({ queryOrigin, queryDestination, queryDate });

  return (
    <>
      {isBusy && <div className="loading"><span className="loader"></span></div>}
      <Form
        id="search-form"
        replace
        method="GET"
        className="flex flex-col items-stretch justify-start gap-2 light-border-space content"
      >
        {(() => {
          switch (type) {
            case 'one-way':
              return <Search loaderData={loaderData} type="one-way" />;
            case 'round-trip':
              return (
                <div className="round-trip-wrapper round-trip">
                  <span className="search-wrapper round-trip-depart"><Search loaderData={loaderData} type="round-trip-depart" /></span>
                  <span className="search-wrapper round-trip-return"><Search loaderData={loaderData} type="round-trip-return" /></span>
                </div>
              );
            case 'trip-builder':
              return <TripBuilder loaderData={loaderData} type="trip-builder" />;
            default:
              return <Search loaderData={loaderData} type={type} />;
          }
        })()}

        {!hideFlightCard && (
          <>
            <fieldset className="flex flex-row items-stretch justify-start gap-2 search-button-wrapper" disabled={isBusy}>
              <Button id="search" className="flex-1 pulse" type="submit">Search</Button>
            </fieldset>
            <fieldset className="flex flex-row items-stretch justify-start gap-2 clear-button-wrapper" disabled={isBusy}>
              <a
                href="/"
                className="font-medium text-sm text-center text-white border border-opacity-50 px-3 py-1 rounded-md block border-red-400 bg-red-500 hover:border-red-300 disabled:cursor-not-allowed disabled:opacity-60 disabled:border-transparent disabled:hover:border-transparent focus:outline-none focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-blue-500 flex-1"
                type="cancel"
              >
                Clear
              </a>
            </fieldset>
            <div className={`results-wrapper ${type}`}>
              <div className="results">
                <div className="results-inner">
                  <h1 className="font-bold text-2xl bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 text-transparent content">Departing <span className="emoji">🛫</span></h1>
                  <FlightCardWrapper>
                    {loaderData?.error ? (
                      <p className="text-center text-red-400 py-6">{loaderData?.error}</p>
                    ) : null}
                    {loaderData?.results?.length === 0 ? (
                      <div className="py-6 flex flex-col items-stretch justify-start gap-4">
                        <p className="text-center">
                          No results found you may have found a soft spot in our data  <span className="emoji">🖬</span>
                        </p>
                        <p className="text-center text-sm">We may still be able to find routes</p>
                        <div
                          ref={notifyFormRef}

                          method="POST"
                          className="flex flex-col items-stretch justify-start gap-2"
                        >
                          <fieldset
                            className="w-[min(320px,_100%)] mx-auto flex flex-col items-stretch justify-center flex-wrap gap-2"
                            disabled={isBusy}
                          >
                            <input type="hidden" name="query" value={loaderData.query} />
                            <Button>Request data refresh</Button>
                          </fieldset>
                        </div>
                        {notifyFetcher.state === "idle" ? (
                          <>
                            {notifyFetcher.data?.result ? (
                              <p className="text-center text-green-400">{notifyFetcher.data.result}</p>
                            ) : null}
                            {notifyFetcher.data?.error ? (
                              <p className="text-center text-red-400">{notifyFetcher.data.error}</p>
                            ) : null}
                          </>
                        ) : (
                          <p className="text-center">Wait...</p>
                        )}
                      </div>
                    ) : null}
                    {loaderData?.results?.map((result) => (
                      <Flight setBusy={setBusy} loaderData={loaderData} flight={result} airports={loaderData.airportsAll} key={uuidv4()} />
                    ))}
                  </FlightCardWrapper>

                </div>
                <label className="pager-title">Page</label>
                <fieldset data-page={loaderData.page} className="pager-wrapper flex flex-row items-stretch justify-start gap-2">
                  <input type="hidden" name={type === "round-trip-return" ? "page2" : "page"} value={sliderValue} />
                  <Button value={-1} className="w-full md:w-auto" onClick={() => handlePageButton(-1)}>Prev</Button>
                  <Slider
                    defaultValue={[sliderValue]}
                    disabled={isBusy}
                    className={sliderValue}
                    min={1}
                    max={loaderData.pageMax}
                    onChange={(newValue) => {
                      setSliderValue(newValue);
                      if (type === "round-trip-return") {
                        loaderData.page2 = newValue;
                      }
                      else {
                        loaderData.page = newValue;
                      }

                    }}
                    onPointerUp={handlePageSliderChange}
                    step={1}
                  />
                  <Button value={1} className="w-full md:w-auto" onClick={() => handlePageButton(1)}>Next</Button>
                </fieldset>
              </div>
              <div className="results">
                <h1 className="font-bold text-2xl bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 text-transparent content">Returning <span className="emoji">🛬</span></h1>
                <div className="results-inner">
                  <FlightCardWrapper>
                    {loaderData?.error ? (
                      <p className="text-center text-red-400 py-6">{loaderData?.error}</p>
                    ) : null}
                    {loaderData?.results2?.length === 0 ? (
                      <div className="py-6 flex flex-col items-stretch justify-start gap-4">
                        <p className="text-center">
                          No results found you may have found a soft spot in our data  <span className="emoji">🖬</span>
                        </p>
                        <p className="text-center text-sm">We may still be able to find routes </p>
                        <div

                          className="flex flex-col items-stretch justify-start gap-2"
                        >
                          <fieldset
                            className="w-[min(320px,_100%)] mx-auto flex flex-col items-stretch justify-center flex-wrap gap-2"
                            disabled={isBusy}
                          >

                            <Button data-query={loaderData.query}>Request data refresh</Button>
                          </fieldset>
                        </div>
                        {notifyFetcher.state === "idle" ? (
                          <>
                            {notifyFetcher.data?.result ? (
                              <p className="text-center text-green-400">{notifyFetcher.data.result}</p>
                            ) : null}
                            {notifyFetcher.data?.error ? (
                              <p className="text-center text-red-400">{notifyFetcher.data.error}</p>
                            ) : null}
                          </>
                        ) : (
                          <p className="text-center">Wait...</p>
                        )}
                      </div>
                    ) : null}
                    {loaderData?.results2?.map((result) => (
                      <Flight setBusy={setBusy} loaderData={loaderData} flight={result} airports={loaderData.airportsAll} key={uuidv4()} />
                    ))}
                  </FlightCardWrapper>

                </div>
                <label className="pager-title">Page</label>
                <fieldset data-page={loaderData.page} className="flex flex-row items-stretch justify-start gap-2">

                  {type !== "round-trip-return" ? (
                    <>
                      <input type="hidden" name="page2" value={sliderValue2} />
                      <Button value={-1} className="w-full md:w-auto" onClick={() => handlePageButton2(-1)}>Prev</Button>
                      <Slider
                        defaultValue={[sliderValue2]}
                        disabled={isBusy}
                        className={sliderValue2}
                        min={1}
                        max={loaderData.pageMax2}
                        onChange={(newValue2) => {
                          setSliderValue2(newValue2);
                          loaderData.page2 = newValue2;
                        }}
                        onPointerUp={handlePageSliderChange}
                        step={1}
                      />
                      <Button value={1} className="w-full md:w-auto" onClick={() => handlePageButton2(1)}>Next</Button>
                    </>
                  ) : null}
                </fieldset>
              </div>
            </div>

          </>
        )}
      </Form>
    </>
  );
}
