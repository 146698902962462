import { useEffect, useRef, useState } from "react";
import { useFetcher, useTransition } from "@remix-run/react";
import { initialModals } from "../../helpers/modals";
import Field from "./Field";
import Switch from "./Switch";
import Slider from "./Slider";
import Modal from "./Modal";


import DatePicker from "react-datepicker";


import Button from "./Button";

export default function Search({ loaderData, type }) {


  if (loaderData.days === undefined || loaderData.days === null) {
    loaderData.days = 2;
  }

  if (loaderData.count === undefined || loaderData.count === null) {
    loaderData.count = 1;
  }

  if (loaderData.page === undefined || loaderData.page === null) {
    loaderData.page = 1;
  }
  if (loaderData.page2 === undefined || loaderData.page2 === null) {
    loaderData.page2 = 1;
  }
  if (loaderData.pageMax === undefined || loaderData.pageMax === null) {
    loaderData.pageMax = 1;
  }
  if (loaderData.pageMax2 === undefined || loaderData.pageMax2 === null) {
    loaderData.pageMax2 = 1;
  }
  const transition = useTransition();
  const notifyFormRef = useRef();
  const notifyFetcher = useFetcher();
  const status = loaderData?.status;

  const handleSwapButtonClick = () => {
    const originFieldValue = document.getElementById('origin').value;
    const destinationFieldValue = document.getElementById('destination').value;

    // Swap the values
    document.getElementById('origin').value = destinationFieldValue;
    document.getElementById('destination').value = originFieldValue;
    document.getElementById("search").click()

  };

  const isBusy = transition.state !== "idle" || notifyFetcher.state !== "idle";

  const [modals, setModals] = useState([]);

  const openModal = (index) => {
    const newModals = [...modals];
    newModals[index].isOpen = true;
    setModals(newModals);
  };

  const closeModal = (index) => {
    const newModals = [...modals];
    newModals[index].isOpen = false;
    setModals(newModals);
  };

  useEffect(() => {

    setModals(initialModals);
  }, []);

  useEffect(function () {
    if (notifyFetcher.type === "done" && notifyFetcher.data?.result) {
      //notifyFormRef.current.reset();
    }
  }, [notifyFetcher]);

  useEffect(
    function () {
      notifyFetcher.data = {};
    },
    [notifyFetcher, loaderData?.query]
  );


  const [value, setValue] = useState(() => {
    const date = type === "round-trip-return" ? loaderData.date2 : loaderData.date;
    return {
      startDate: date,
      endDate: date,
    };
  });



  const [sliderValue, setSliderValue] = useState(loaderData.page);

  loaderData.date = value.startDate;



  const similarDateClick = (newValue) => {
    let date = newValue.target.value

    console.log(newValue)

    setValue({ startDate: date, endDate: date });

    let search = newValue.target.closest('.search')

    search.querySelector('.hidden-date').value = date
    search.querySelector('.react-datepicker__input-container input').value = date
    document.getElementById("search").click()


  };




  const handleValueChange = (newValue) => {
    newValue.setHours(0, 0, 0, 0);

    console.log(newValue)
    // Map month names to their corresponding numbers
    const monthMap = {
      Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
      Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };

    let dateValues = newValue.toDateString().split(' ')
    // Convert the month name to a number
    const monthNumber = monthMap[dateValues[1]];

    // Rearrange the parts to YYYY-MM-DD format
    let date = `${dateValues[3]}-${monthNumber}-${dateValues[2]}`;

    console.log(date)

    setValue({ startDate: date, endDate: date });

    if (type === "round-trip-return") {
      loaderData.date2 = date;
    } else {
      loaderData.date = date;
    }
  };

  let queryOrigin = loaderData?.origin;
  let queryDestination = loaderData?.destination;
  let queryDate = loaderData?.date;

  let similarResults = loaderData?.similarResults

  loaderData.query = JSON.stringify({
    queryOrigin,
    queryDestination,
    queryDate,
  });

  function convertDate(date) {
    const [year, month, day] = date.split('-').map(Number);

    // Create a Date object using the parsed values
    const localDate = new Date(year, month - 1, day);

    // Adjust the date to UTC+14
    const offset = 1 * 60; // UTC+14 in minutes
    const adjustedDate = new Date(localDate.getTime() + offset * 60 * 1000);

    return adjustedDate;
  }


  return (
    <div className="search">

      <Field
        id="type"
        value={type}
        className="hidden"
        readOnly={true}
        name="type"
      >
      </Field>


      <fieldset
        className="flex flex-row items-stretch justify-start gap-2 date-picker-fieldset content paragraph"
        disabled={isBusy}
      >

        <Field
          id={type === "round-trip-return" ? "date2" : "date"}
          value={value.startDate}
          className="hidden paragraph content hidden-date"
          name={type === "round-trip-return" ? "date2" : "date"}
          label={`<span class="content"">${type === "round-trip-return" ? "Date of return" : "Date of departure"}&nbsp; </span><span className="emoji">📅</span>`}
          title="Date for when you want to start your trip"
          required={true}
          readOnly={true}
        >


          <DatePicker selected={convertDate(value.startDate)} onChange={handleValueChange} />

        </Field>
      </fieldset>
      {similarResults.length && !similarResults.every(item => item === null) ? (
        <fieldset
          className="similar-dates-wrapper  content paragraph"
          disabled={isBusy}
        >
          <label className="font-medium text-xs uppercase whitespace-nowrap paragraph form-label">


            <span className="content">Similar Dates {type === 'round-trip-return' ? "back to Origin" : "to Destination"}&nbsp; </span><span className="emoji">🤑</span>

          </label>
          <ul className="similar-dates">
            {similarResults.map((result, index) =>
              result ? (
                <li key={index} className="date total-cost">


                  <Button
                    type="button"
                    onClick={similarDateClick}
                    value={result.date}
                    className="similar-date-button "
                  >
                    {result.dateDisplay} ${result.price}.00
                  </Button>

                </li>
              ) : null
            )}
          </ul>
        </fieldset>
      ) : (
        null
      )}

      <fieldset
        className="  flex flex-col items-start justify-start gap-2"
        disabled={isBusy}
      >
        <div className="flex items-center">
          <label className="font-medium text-xs uppercase whitespace-nowrap paragraph form-label">


            <span className="content">Number of Waypoints {type === 'round-trip-return' ? "back to Origin" : "to Destination"}&nbsp; </span><span className="emoji">🌍</span>


            {status !== 2 ? (
              <span role="img" className="emoji" aria-label="PRO feature">
                🔒
              </span>
            ) : (
              <span role="img" className="emoji" aria-label="PRO feature">
                ✔️
              </span>
            )}
          </label>
          <button
            className="question pulse flex items-center text-xs justify-center w-5 h-5 bg-green-700 text-white rounded-full border border-white ml-2 focus:outline-none hover:bg-green-600"
            onClick={() => openModal(0)}
            type="button"
          >
            ?
          </button>
        </div>
        {modals[0] && (
          <Modal
            isOpen={modals[0].isOpen}
            onClose={() => closeModal(0)}
            title={modals[0].title}
            content={modals[0].content}
          />
        )}
        <Slider
          name={type === "round-trip-return" ? "count2" : "count"}
          defaultValue={[loaderData.count]}
          disabled={isBusy || status !== 2}
          className=""
          min={1}
          max={3}
          step={1}
        />
      </fieldset>

      <fieldset
        className="  flex flex-row items-stretch justify-start gap-2 paragraph"
        disabled={isBusy}
      >
        <div className="min-w-0 flex-1 flex flex-col items-stretch justify-start gap-1">
          <label
            className="font-medium text-xs uppercase whitespace-nowrap"
            title="How many days you want to stay at each city before your final destination."
          >
            <span className="content">Number of days at each Waypoint&nbsp; </span><span className="emoji">📅</span>
            {modals[3] && (
              <>
                <button
                  className="question pulse flex items-center text-xs justify-center w-5 h-5 bg-green-700 text-white rounded-full border border-white ml-2 focus:outline-none hover:bg-green-600"
                  onClick={() => openModal(3)}
                  type="button"
                > ?
                </button>
                <Modal
                  isOpen={modals[3].isOpen}
                  onClose={() => closeModal(3)}
                  title={modals[3].title}
                  content={modals[3].content}
                />
              </>
            )}
          </label>

          <Slider
            name={type === "round-trip-return" ? "days2" : "days"}
            defaultValue={type === "round-trip-return" ? [loaderData.days2] : [loaderData.days]}
            min={0}
            max={5}
            step={1}
          />
        </div>
      </fieldset>

      <fieldset
        className="  flex flex-row items-stretch justify-start gap-2 origin-destination-fieldset"
        disabled={isBusy}
      >
        <span className="has-clear">
          <Field
            id="origin"
            className="w-full md:w-auto origin "
            type="text"
            name="origin"
            label='<span class="content">Departures&nbsp; </span><span className="emoji">🛫</span>'
            title="The location you begin your journey"
            placeholder="Origin"
            required={true}
            autoComplete="off"
            autocompletedata={loaderData.airportsAll}
            defaultValue={loaderData.origin}
          />
        </span>
        <Button
          type="button"
          onClick={handleSwapButtonClick}
          className="swap-button "
        >
          🔄
        </Button>
        <span className="has-clear">
          <Field
            id="destination"
            className="w-full md:w-auto destination"
            type="text"
            title="Your final destination"
            name="destination"
            label='<span class="content">Arrivals&nbsp; </span><span className="emoji">🛬</span>'
            placeholder="Destination"
            required={true}
            autoComplete="off"
            autocompletedata={loaderData.airportsAll}
            defaultValue={loaderData.destination}
          />
        </span>
      </fieldset >
      <fieldset className="exclude-fieldset paragraph" disabled={isBusy}>
        <div className="flex items-center">
          <label className="font-medium text-xs uppercase whitespace-nowrap">
            <span className="content"> Exclude Waypoints&nbsp;</span><span className="emoji">🌍</span>
            {status !== 2 ? (
              <span role="img" className="emoji" aria-label="PRO feature">
                🔒
              </span>
            ) : (
              <span role="img" className="emoji" aria-label="PRO feature">
                ✔️
              </span>
            )}
          </label>
          <button
            className="question pulse flex items-center text-xs justify-center w-5 h-5 bg-green-700 text-white rounded-full border border-white ml-2 focus:outline-none hover:bg-green-600"
            onClick={() => openModal(1)}
            type="button"
          >
            ?
          </button>
        </div>
        {modals[1] && (
          <Modal
            isOpen={modals[1].isOpen}
            onClose={() => closeModal(1)}
            title={modals[1].title}
            content={modals[1].content}
          />
        )}
        <span className="has-clear">
          <Field
            id="exclude"
            className="w-full md:w-auto exclude"
            type="text"
            name="exclude"
            placeholder="Exclude"
            disabled={isBusy || status !== 2}
            required={false}
            autoComplete="off"
            autocompletedata={loaderData.airportsAll}
            multiple={true}
            defaultValue={loaderData.exclude}
          />
        </span>

      </fieldset>
      <fieldset className="content   quality-fieldset" disabled={isBusy}>
        <div className="flex  paragraph items-center">
          <label className="font-medium text-xs uppercase whitespace-nowrap content">
            Quality&nbsp;
            {status !== 2 ? (
              <span role="img" className="emoji" aria-label="PRO feature">
                🔒
              </span>
            ) : (
              <span role="img" className="emoji" aria-label="PRO feature">
                ✔️
              </span>
            )}
          </label>
          <button
            className="question pulse  flex items-center text-xs justify-center w-5 h-5 bg-green-700 text-white rounded-full border border-white ml-2 focus:outline-none hover:bg-green-600"
            onClick={() => openModal(2)}
            type="button"
          >
            ?
          </button>
        </div>
        {modals[1] && (
          <Modal
            isOpen={modals[2].isOpen}
            onClose={() => closeModal(2)}
            title={modals[2].title}
            content={modals[2].content}
          />
        )}
        <Switch
          defaultEnabled={loaderData.quality === 'on'}
          disabled={isBusy || status !== 2} id="quality" name="quality"></Switch>
      </fieldset>

    </div>
  );
}
