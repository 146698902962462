import { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import Button from "./Button";
import External from "../icons/External";
import { tripBuilderSet } from "../../helpers/waypoints.js";
import { v4 as uuidv4 } from 'uuid';


export default function InformationButton({ uuid, route, status, airports }) {
  const codes = route.split('-');
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    const handleTouchOutside = (event) => {
      if (!event.target.closest('.information-button-container')) {
        setIsTooltipVisible(false);
      }
    };

    document.body.addEventListener('touchstart', handleTouchOutside);

    return () => {
      document.body.removeEventListener('touchstart', handleTouchOutside);
    };
  }, []);

  const handleTooltipVisibility = (value) => {
    if (value === true) {
      document.body.classList.remove('disabled');
      document.body.classList.add('disabled');
    }

    else {
      document.body.classList.remove('disabled');
    }

    setIsTooltipVisible(value);
  };

  return (
    <div className="information-button-container">
      <Button
        className="information-button pulse"
        type="button"
        onClick={() => handleTooltipVisibility(true)}
      >
        <span className="desktop-only">PRO Travel Agent 🤖✨</span>
        <span className="mobile-only">PRO Agent 🤖✨</span>
      </Button>
      {isTooltipVisible && (
        <div className="information-wrapper paragraph">
          <h1 className="information-header font-bold text-2xl bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 text-transparent">PRO Travel Agent</h1>
          <div className="information-inner">
            <button className="close-button" onClick={() => handleTooltipVisibility(false)}>
              Close
            </button>
            {codes.map((code, index) => (
              <InformationContainer key={`${uuid}-${index}`} code={code} status={status} airports={airports} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
export function InformationContainer({ code, status, airports }) {
  const googleMapsLink = `https://www.google.com/maps/search/${code}+airport`;
  const airport = airports.find((airport) => airport.code === code) || {};
  const airportMeta = airport.meta || {};

  return (
    <span key={code} className="information-container">
      <div className="information">
        <div className="header">
          <div className="title">
            <a
              className="name italic text-neutral-400"
              href={googleMapsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {airport.name}
            </a>
            <a
              className="sub-name font-medium text-xs uppercase"
              href={googleMapsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {airport.country}
            </a>
            <div className="meta danger font-medium text-xs uppercase">
              <span>Danger Rating: {airportMeta.danger || "Unknown"}</span>
            </div>
            <div className="meta uber-lyft  font-medium text-xs uppercase">
              <span>Uber: {airportMeta?.rideshare?.uber || "No"}</span>&nbsp;
              <span>Lyft: {airportMeta?.rideshare?.lyft || "No"}</span>
            </div>
          </div>
          <div className="actions">
            <a
              className="action-button map pulse"
              href={googleMapsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Map of Location <span className="emoji">🗺️</span>
            </a>
            <span
              className="action-button exclude pulse"
              onClick={(event) => onRemove(event, status)}
              data-code={code}
            >
              {status === 2 && (
                <>
                  Exclude Airport From Search
                  <span className="emoji">
                    ❌
                  </span>
                </>
              )}
              {status !== 2 && (
                <>
                  Exclude Airport From Search
                  <span className="emoji">🔒</span>
                </>
              )}
            </span>
          </div>
        </div>
        <h4 className="sub-name data-label font-medium text-xs uppercase">INFORMATION:</h4>
        <div className="information-meta">
          <div className="meta visa font-medium">
            {status === 2 && (
              <>
                <label>
                  Visa Entry Requirements:


                </label>
                <div className="item ">{airportMeta.visa || "None"}</div>
              </>
            )}
            {status !== 2 && (
              <>
                <label>
                  <label>
                    <span className="emoji">🔒</span>&nbsp;Visa Entry Requirements:
                  </label>

                </label>
                <div className="item purchase-cta">
                  Get simplified visa requirements and detailed step by step guides for US Citizens
                  <br /><br />

                  {!name && (
                    <span className="activate-pro">

                      <div className="needs-login">
                        <button className="activate-pro-button yellow" onClick={() => {
                          document.querySelector('.login').click()
                        }}>Please Log in and Active PRO</button>
                      </div>

                    </span>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="meta lounges font-medium">
            {status === 2 && (
              <>
                <label>
                  Airport Lounge Information:


                </label>
                <div className="item ">{airportMeta.lounges || "None"}</div>
              </>
            )}
            {status !== 2 && (
              <>
                <label>
                  <span className="emoji">🔒</span>&nbsp;Airport Lounge Information:
                </label>
                <div className="item ">
                  Get quick information on airport lounge access.
                  <br /><br />

                  {!name && (
                    <span className="activate-pro">

                      <div className="needs-login">
                        <button className="activate-pro-button yellow" onClick={() => {
                          document.querySelector('.login').click()
                        }}>Please Log in and Active PRO</button>
                      </div>

                    </span>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="meta surrounding font-medium">
            {status === 2 && (
              <>
                <label>
                  Surrounding Airports


                </label>
                <div className="item ">{airportMeta.surrounding || "None"}</div>
              </>
            )}
            {status !== 2 && (
              <>
                <label>
                  <span className="emoji">🔒</span>&nbsp;Surrounding Airports:
                </label>
                <div className="item ">
                  See surrounding airports
                  <br /><br />

                  {!name && (
                    <span className="activate-pro">

                      <div className="needs-login">
                        <button className="activate-pro-button yellow" onClick={() => {
                          document.querySelector('.login').click()
                        }}>Please Log in and Active PRO</button>
                      </div>

                    </span>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="meta rentals font-medium">
            {status === 2 && (
              <>
                <label>
                  Rental Car Information


                </label>
                <div className="item ">{airportMeta.rentals || "None"}</div>
              </>
            )}
            {status !== 2 && (
              <>
                <label>
                  <span className="emoji">🔒</span>&nbsp;Rental Car Information:
                </label>
                <div className="item ">
                  See normal price ranges for this location.
                  <br /><br />

                  {!name && (
                    <span className="activate-pro">

                      <div className="needs-login">
                        <button className="activate-pro-button yellow" onClick={() => {
                          document.querySelector('.login').click()
                        }}>Please Log in and Active PRO</button>
                      </div>

                    </span>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="meta public font-medium">
            {status === 2 && (
              <>
                <label>
                  Public Transportation Score:


                </label>
                <div className="item ">{airportMeta.public_transportation_rating || "None"}</div>
                <div className="item ">{airportMeta.public_transportation_summary || "None"}</div>
              </>
            )}
            {status !== 2 && (
              <>
                <label>
                  <span className="emoji">🔒</span>&nbsp;PRO Public Transportation Score:
                </label>
                <div className="item ">
                  Check out our AI-enhanced PRO Public Transportation Score, derived from calculations involving real time and real cost variables.
                  <br /><br />

                  {!name && (
                    <span className="activate-pro">

                      <div className="needs-login">
                        <button className="activate-pro-button yellow" onClick={() => {
                          document.querySelector('.login').click()
                        }}>Please Log in and Active PRO</button>
                      </div>

                    </span>
                  )}
                </div>
              </>
            )}
          </div>




          <div className="meta rideshare font-medium">
            {status === 2 && (
              <>
                <label>
                  Uber / Lyft / Rideshare Information


                </label>
                <div className="item ">

                  {airportMeta.rideshare && (
                    <>
                      <div className="rideshare-inner uber">
                        Has Uber: {airportMeta.rideshare.uber}
                      </div>
                      <div className="rideshare-inner uber">
                        Has Lyft: {airportMeta.rideshare.lyft}
                      </div>
                      <div className="rideshare-inner rideshare">
                        Rideshare Information: {airportMeta.rideshare.meta}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {status !== 2 && (
              <>
                <label>
                  <span className="emoji">🔒</span>&nbsp;Uber / Lyft / Rideshare Information:
                </label>
                <div className="item ">
                  Quickly see if Uber and Lyft are available and detailed information on average cost from airport to city center.
                  <br /><br />

                  {!name && (
                    <span className="activate-pro">

                      <div className="needs-login">
                        <button className="activate-pro-button yellow" onClick={() => {
                          document.querySelector('.login').click()
                        }}>Please Log in and Active PRO</button>
                      </div>

                    </span>
                  )}
                </div>
              </>
            )}
          </div>



          <div className="meta hotels font-medium">
            {status === 2 && (
              <>
                <label>
                  Hotel Information


                </label>
                <div className="item ">{airportMeta.hotels || "None"}</div>
              </>
            )}
            {status !== 2 && (
              <>
                <label>
                  <span className="emoji">🔒</span>&nbsp;Hotel Information:
                </label>
                <div className="item ">
                  Get average hotel price ranges for the area.
                  <br /><br />

                  {!name && (
                    <span className="activate-pro">

                      <div className="needs-login">
                        <button className="activate-pro-button yellow" onClick={() => {
                          document.querySelector('.login').click()
                        }}>Please Log in and Active PRO</button>
                      </div>

                    </span>
                  )}
                </div>
              </>
            )}
          </div>

          {
            /*
            city = airport['city']
            activities = subprocess.check_output(['a', 'Give me top 10 things to do for this city: ' + city + ' keep the ansswers to 1 sentance.']).decode('utf-8').strip()
            description = subprocess.check_output(['a', 'Give a short 2 sentance description of this city: ' + city + '']).decode('utf-8').strip()
            lounges = subprocess.check_output(['a', 'does ' + code + ' have any airport lounges that specifically a priority pass member can enter, this is an API request so answer as a list of lounge names and nothing else']).decode('utf-8').strip()
            surrounding = subprocess.check_output(['a', 'does ' + code + ' have any nearby airports within 50 miles, this is an API request so answer as a list of airport codes and nothing else. just the codes not the names. just provide a list of 3 character codes']).decode('utf-8').strip()
            
            danger_summary = subprocess.check_output(['a', 'Give me a very general summary of the safety as a traveler in the city: ' + city +  ' ']).decode('utf-8').strip()
            danger_scams = subprocess.check_output(['a', 'As a traveler to:  ' + city +  ' what are the top 5 most common street scams to avoid']).decode('utf-8').strip()
            danger_areas = subprocess.check_output(['a', 'In the city:' + city + ' list the top 5 worst crime rate areas']).decode('utf-8').strip()
            danger = subprocess.check_output(['a', 'On a scale of 1-5, 5 being the most dangerous for an American Citizen give your best guess for this city: ' + city + ' . AN API IS REQUESTING THIS DATA PLEASE RESPOND ONLY with an integer from 1 to 5']).decode('utf-8').strip()

            visa = subprocess.check_output(['a', 'As a US Citizen traveler to: ' + city +  ',' + country + 'are there any special visa requirements for entry']).decode('utf-8').strip()
            rideshare = subprocess.check_output(['a', 'If uber or lyft available at  ' + code + ' . what are the price ranges typically to get to the city center. what are alternative rideshare companies that are available']).decode('utf-8').strip() 
            uber = subprocess.check_output(['a', 'Yes or no is Uber available at ' + code + ' ']).decode('utf-8').strip()
            lyft = subprocess.check_output(['a', 'Yes or no is Uber available at ' + code + ' ']).decode('utf-8').strip()
            */
          }
          <div className="meta description">
            <label>Travel Risks Summary:</label>
            <div className="item">
              {airportMeta.danger_summary || "No data available"}
              {airportMeta.danger_scams || "No data available"}
              {airportMeta.danger_areas || "No data available"}
              {airportMeta.danger || "No data available"}
            </div>
          </div>
          <div className="meta description">
            <label>Area description:</label>
            <div className="item">
              {airportMeta.description || "No description available"}
            </div>
          </div>
          <div className="meta activities font-medium">
            <label>Things to do:</label>
            <div className="item ">{airportMeta.activities || "None"}</div>
          </div>
        </div>
      </div>
    </span >
  );
}

























