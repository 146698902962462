import { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import Button from "./Button";
import Switch from "./Switch";
import InformationButton from "./Information";
import { tripBuilderSet } from "../../helpers/waypoints.js";
import External from "../icons/External";
const { v4: uuidv4 } = require('uuid');

export function Trip({ trip, status, airports }) {



  const addToTripBuilder = (event) => {
    event.preventDefault();
    event.stopPropagation();

    let name = event.target.parentElement.getAttribute('data-name') !== null ? event.target.parentElement.getAttribute('data-name') : event.target.parentElement.parentElement.getAttribute('data-name')
    let checked = event.target.parentElement.parentElement.querySelector('button').getAttribute('aria-checked') === "true" ? 0 : 1

    trip.waypoint.meta[name] = checked

    let o = {}
    let m1 = {}
    let m2 = {}
    let d = {}

    o = {
      "date": trip.originFlight.date,
      "t": trip.originFlight.timestamp,
      "o": trip.originFlight.origin,
      "d": trip.originFlight.destination,
      "p": trip.originFlight.price,
      "du": trip.originFlight.duration,
      "dt": trip.originFlight.departureTime,
      "at": trip.originFlight.arrivalTime,
    }

    d = {
      "date": trip.destinationFlight.date,
      "t": trip.destinationFlight.timestamp,
      "o": trip.destinationFlight.origin,
      "d": trip.destinationFlight.destination,
      "p": trip.destinationFlight.price,
      "du": trip.destinationFlight.duration,
      "dt": trip.destinationFlight.departureTime,
      "at": trip.destinationFlight.arrivalTime,
    }


    if (trip.middleFlight1) {
      m1 = {
        "date": trip.middleFlight1.date,
        "t": trip.middleFlight1.timestamp,
        "o": trip.middleFlight1.origin,
        "d": trip.middleFlight1.destination,
        "p": trip.middleFlight1.price,
        "du": trip.middleFlight1.duration,
        "dt": trip.middleFlight1.departureTime,
        "at": trip.middleFlight1.arrivalTime,
      }
    }

    if (trip.middleFlight2) {
      m2 = {
        "date": trip.middleFlight2.date,
        "t": trip.middleFlight2.timestamp,
        "o": trip.middleFlight2.origin,
        "d": trip.middleFlight2.destination,
        "p": trip.middleFlight2.price,
        "du": trip.middleFlight2.duration,
        "dt": trip.middleFlight2.departureTime,
        "at": trip.middleFlight2.arrivalTime,
      }
    }


    let compressedTrip = {
      o, d, m1, m2,
      w: trip.waypoint
    };


    tripBuilderSet(compressedTrip);
  };



  let dateOrigin = '';
  let dateOriginWordy = '';
  let middleDate1 = '';
  let middleDate1Wordy = '';
  let middleDate2 = '';
  let middleDate2Wordy = '';
  let dateDestination = '';
  let dateDestinationWordy = '';
  let airportMeta = {}
  let uuid = uuidv4();


  if (airports.length) {
    airportMeta = airports.find(airport => airport.code === trip?.originFlight?.origin)
  }

  if (trip.originFlight && typeof trip.originFlight.date !== 'undefined') {
    dateOrigin = `${trip.originFlight.date} at ${trip.originFlight.departureTime}`;
    dateOriginWordy = format(parseISO(trip.originFlight.date), 'MMMM d y');
  }

  if (typeof trip?.middleFlight1?.origin !== "undefined" && trip?.middleFlight1?.origin !== undefined) {
    middleDate1 = `${trip.middleFlight1.date} at ${trip.middleFlight1.departureTime}`;
    middleDate1Wordy = format(parseISO(trip.middleFlight1.date), 'MMMM d y');
  }

  if (typeof trip?.middleFlight2?.origin !== "undefined" && trip?.middleFlight2?.origin !== undefined) {
    middleDate2 = `${trip.middleFlight2.date} at ${trip.middleFlight2.departureTime}`;
    middleDate2Wordy = format(parseISO(trip.middleFlight2.date), 'MMMM d y');
  }
  if (trip.destinationFlight && typeof trip.destinationFlight.date !== 'undefined') {
    dateDestination = `${trip.destinationFlight.date} at ${trip.destinationFlight.arrivalTime}`;
    dateDestinationWordy = format(parseISO(trip.destinationFlight.date), 'MMMM d y');
  }






  return (
    <>

      <TripCard
        key={uuidv4()}
        className={[
          "flex flex-col items-stretch justify-start gap-4 trip-card"]}
      >
        <div className="trip-planner flex flex-row items-start justify-start gap-2">
          <div className="trip-planner-information flex-1 flex flex-col items-stretch justify-start gap-2  ">
            <div className="flex flex-col items-stretch justify-start">
              <span className="font-bold route-info">
                <span className="route">{trip.waypoint.route}</span>
              </span>
              <h5 className="font-bold text-2xl total-cost">Total Cost: <span className="bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 total-cost text-transparent">${parseFloat(trip?.waypoint?.price).toFixed(2)}</span></h5>
              <h5 className="font-bold text-medium">Date: <span className="bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 text-transparent">{trip?.originFlight?.date}</span></h5>
              <h5 className="font-bold text-medium">Departure Time: <span className="bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 text-transparent">{trip?.originFlight?.departureTime}</span></h5>

              <InformationButton uuid={uuid} route={trip.waypoint.route} status={status} airports={airports} />

            </div>
          </div>
          <div className="trip-planner-switches flex-1 flex flex-col items-stretch justify-end gap-2  ">
            <div className="flex-1 flex flex-col items-stretch justify-end gap-2">
              <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                <div onClick={addToTripBuilder} data-name="h1" className="switch flex-1 flex flex-row items-stretch justify-between  gap-2">
                  <label className="switch-label">Booked Hotel?</label>
                  <Switch
                    defaultEnabled={trip.waypoint.meta.h1 == 1}
                    id="1" name="h1">
                  </Switch>
                </div>
                <div onClick={addToTripBuilder} data-name="c1" className="switch flex-1 flex flex-row items-stretch justify-between  gap-2">
                  <label className="switch-label">Booked Rental Car?</label>
                  <Switch
                    defaultEnabled={trip.waypoint.meta.c1 == 1}
                    id="2" name="c1"></Switch>
                </div>
              </div>
              <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
              </div>
              <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                <div onClick={addToTripBuilder} data-name="a1" className="switch flex flex-1 flex-row items-stretch justify-between  gap-2">
                  <label className="switch-label">Booked Activities?</label>
                  <Switch
                    defaultEnabled={trip.waypoint.meta.a1 == 1}
                    id="3" name="a1"></Switch>
                </div>
                <div onClick={addToTripBuilder} data-name="v1" className="switch flex-1 flex flex-row items-stretch justify-between  gap-2">
                  <label className="switch-label">Have Visa?</label>
                  <Switch
                    defaultEnabled={trip.waypoint.meta.v1 == 1}
                    id="4" name="v1"></Switch>
                </div>
              </div>
              <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
              </div>
            </div>
            <Button
              as="a"
              href={`https://www.google.com/search?q=direct+one+way+flight+from+${trip?.originFlight?.origin}+to+${trip?.originFlight?.destination}+on+${encodeURIComponent(dateOriginWordy)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row items-end justify-center gap-2"
            >
              Flight: {trip.originFlight.origin} - {trip.originFlight.destination}
              <External />
            </Button>

            {Object.keys(trip.middleFlight1).length > 1 && trip.middleFlight1.origin !== undefined && (
              <>
                <div className="flex-1 flex flex-col items-stretch justify-end gap-2">
                  <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                    <div onClick={addToTripBuilder} data-name="h2" className="switch flex-1 flex flex-row items-stretch justify-between  gap-2">
                      <label className="switch-label">Booked Hotel?</label>
                      <Switch defaultEnabled={trip.waypoint.meta.h2 == 1}
                        id="11" name="h2">
                      </Switch>
                    </div>
                    <div onClick={addToTripBuilder} data-name="c2" className="switch flex-1 flex flex-row items-stretch justify-between  gap-2">
                      <label className="switch-label">Booked Rental Car?</label>
                      <Switch defaultEnabled={trip.waypoint.meta.c2 == 1}
                        id="12" name="c2"></Switch>
                    </div>
                  </div>
                  <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                  </div>
                  <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                    <div onClick={addToTripBuilder} data-name="a2" className="switch flex-1 flex flex-row items-stretch justify-between  gap-2">
                      <label className="switch-label">Booked Activities?</label>
                      <Switch defaultEnabled={trip.waypoint.meta.a2 == 1}
                        id="13" name="a2"></Switch>
                    </div>
                    <div onClick={addToTripBuilder} data-name="v2" className="switch  flex-1 flex flex-row items-stretch justify-between  gap-2">
                      <label className="switch-label">Have Visa?</label>
                      <Switch defaultEnabled={trip.waypoint.meta.v2 == 1}
                        id="14" name="v2"></Switch>
                    </div>
                  </div>
                  <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                  </div>
                </div>
                <Button
                  as="a"
                  href={`https://www.google.com/search?q=direct+one+way+flight+from+${trip?.middleFlight1?.origin}+to+${trip?.middleFlight1.destination}+on+${encodeURIComponent(middleDate1Wordy)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row items-end justify-center gap-2"
                >
                  Flight: {trip.middleFlight1.origin} - {trip.middleFlight1.destination}
                  <External />
                </Button>
              </>

            )}

            {Object.keys(trip.middleFlight2).length > 1 && trip.middleFlight2.origin !== undefined && (
              <>
                <div className="flex-1 flex flex-col items-stretch justify-end gap-2">
                  <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                    <div onClick={addToTripBuilder} data-name="h3" className="switch  flex-1 flex flex-row items-stretch justify-between  gap-2">
                      <label className="switch-label">Booked Hotel?</label>
                      <Switch defaultEnabled={trip.waypoint.meta.h3 == 1}
                        id="21" name="h3">
                      </Switch>
                    </div>
                    <div onClick={addToTripBuilder} data-name="c3" className="switch  flex-1 flex flex-row items-stretch justify-between  gap-2">
                      <label className="switch-label">Booked Rental Car?</label>
                      <Switch defaultEnabled={trip.waypoint.meta.c3 == 1}
                        id="22" name="c3"></Switch>
                    </div>
                  </div>
                  <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                  </div>
                  <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                    <div onClick={addToTripBuilder} data-name="a3" className="switch  flex-1 flex flex-row items-stretch justify-between  gap-2">
                      <label className="switch-label">Booked Activities?</label>
                      <Switch defaultEnabled={trip.waypoint.meta.a3 == 1}
                        id="23" name="a3"></Switch>
                    </div>
                    <div onClick={addToTripBuilder} data-name="v3" className="switch flex-1  flex flex-row items-stretch justify-between  gap-2">
                      <label className="switch-label">Have Visa?</label>
                      <Switch defaultEnabled={trip.waypoint.meta.v3 == 1}
                        id="24" name="v3"></Switch>
                    </div>
                  </div>
                  <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                  </div>
                </div>
                <Button
                  as="a"
                  href={`https://www.google.com/search?q=direct+one+way+flight+from+${trip.middleFlight2.origin}+to+${trip.middleFlight2.destination}+on+${encodeURIComponent(middleDate2Wordy)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row items-end justify-center gap-2"
                >
                  Flight: {trip.middleFlight2.origin} - {trip.middleFlight2.destination}
                  <External />
                </Button>
              </>

            )}
            <>
              <div className="flex-1 flex flex-col items-stretch justify-end gap-2">
                <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                  <div onClick={addToTripBuilder} data-name="h4" className="switch flex-1  flex flex-row items-stretch justify-between  gap-2">
                    <label className="switch-label">Booked Hotel?</label>
                    <Switch defaultEnabled={trip.waypoint.meta.h4 == 1}
                      id="31" name="h4">
                    </Switch>
                  </div>
                  <div onClick={addToTripBuilder} data-name="c4" className="switch  flex-1 flex flex-row items-stretch justify-between  gap-2">
                    <label className="switch-label">Booked Rental Car?</label>
                    <Switch defaultEnabled={trip.waypoint.meta.c4 == 1}
                      id="32" name="c4"></Switch>
                  </div>
                </div>
                <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                </div>
                <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                  <div onClick={addToTripBuilder} data-name="a4" className="switch flex-1  flex flex-row items-stretch justify-between  gap-2">
                    <label className="switch-label">Booked Activities?</label>
                    <Switch defaultEnabled={trip.waypoint.meta.a4 == 1}
                      id="33" name="a4"></Switch>
                  </div>
                  <div onClick={addToTripBuilder} data-name="v4" className="switch flex-1  flex flex-row items-stretch justify-between  gap-2">
                    <label className="switch-label">Have Visa?</label>
                    <Switch defaultEnabled={trip.waypoint.meta.v4 == 1}
                      id="34" name="v4"></Switch>
                  </div>
                </div>
                <div className="switch-group flex-1 flex flex-row items-stretch justify-end gap-2">
                </div>
              </div>
              <Button
                as="a"
                href={`https://www.google.com/search?q=direct+one+way+flight+from+${trip.destinationFlight.origin}+to+${trip.destinationFlight.destination}+on+${encodeURIComponent(dateDestinationWordy)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-row items-end justify-center gap-2"
              >
                Flight: {trip.destinationFlight.origin} - {trip.destinationFlight.destination}
                <External />
              </Button>
            </>
          </div>
        </div>
      </TripCard>
    </>
  );
}


export function TripCardWrapper({ className = "", children, ...otherProps }) {
  return (
    <div
      key={uuidv4()}
      className={[
        "trip-card-wrapper flex flex-col items-stretch justify-start gap-0 border-y border-dashed border-neutral-800 divide-y divide-dashed divide-neutral-800",
        className,
      ].join(" ")}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export function TripCard({ className = "", children, ...otherProps }) {
  return (
    <article className={["p-4 trip-card-inner", className].join(" ")} {...otherProps}>
      {children}
    </article>
  );
}
