import { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";

import InformationButton from "./Information";
import AirportLink from "./AirportLink";

import Button from "./Button";
import External from "../icons/External";
import { tripBuilderSet, tripBuilderGetAndInflateWithoutCookie, tripBuilderInflate } from "../../helpers/waypoints.js";
import { v4 as uuidv4 } from 'uuid';
import { doc } from "prettier";


let tripFlightMatchCheck = (trip, flight) => {

  flight = JSON.parse(flight)
  if (Object.keys(trip).length < 1 || Object.keys(flight).length < 1 || typeof trip?.originFlight?.origin === "undefined" || typeof flight?.originFlight?.origin === "undefined") {
    return false;
  }

  let matches = (
    flight.originFlight.origin === trip.originFlight.origin &&
    flight.originFlight.destination === trip.originFlight.destination &&
    flight.originFlight.date === trip.originFlight.date &&
    flight.originFlight.price === trip.originFlight.price &&
    flight.originFlight.departureTime === trip.originFlight.departureTime &&
    flight.originFlight.arrivalTime === trip.originFlight.arrivalTime
  )
    && (
      flight.destinationFlight.origin === trip.destinationFlight.origin &&
      flight.destinationFlight.destination === trip.destinationFlight.destination &&
      flight.destinationFlight.date === trip.destinationFlight.date &&
      flight.destinationFlight.price === trip.destinationFlight.price &&
      flight.destinationFlight.departureTime === trip.destinationFlight.departureTime &&
      flight.destinationFlight.arrivalTime === trip.destinationFlight.arrivalTime
    )

  if (matches) {
    //console.log("CHECK MATCH", { trip, flight })
  }

  return matches
}

export function Flight({ setBusy, loaderData, flight, airports }) {

  const [uuid] = useState(uuidv4());

  let trip1 = {}
  let trip2 = {}
  let flight1 = {}
  let trip_builder = []
  let disabled = false;
  let status = loaderData.status;




  let originFlight = {
    "date": flight.originFlight.date,
    "timestamp": flight.originFlight.timestamp,
    "origin": flight.originFlight.origin,
    "destination": flight.originFlight.destination,
    "price": flight.originFlight.price,
    "duration": flight.originFlight.duration,
    "departureTime": flight.originFlight.departureTime,
    "arrivalTime": flight.originFlight.arrivalTime,
  }

  let destinationFlight = {
    "date": flight.destinationFlight.date,
    "timestamp": flight.destinationFlight.timestamp,
    "origin": flight.destinationFlight.origin,
    "destination": flight.destinationFlight.destination,
    "price": flight.destinationFlight.price,
    "duration": flight.destinationFlight.duration,
    "departureTime": flight.destinationFlight.departureTime,
    "arrivalTime": flight.destinationFlight.arrivalTime,
  }


  if (loaderData.trip_builder.length) {
    if (typeof loaderData.trip_builder[1]?.originFlight?.date !== "undefined" && loaderData.trip_builder.length > 1) {
      let originFlight2 = {
        "date": loaderData.trip_builder[1].originFlight.date,
        "timestamp": loaderData.trip_builder[1].originFlight.timestamp,
        "origin": loaderData.trip_builder[1].originFlight.origin,
        "destination": loaderData.trip_builder[1].originFlight.destination,
        "price": loaderData.trip_builder[1].originFlight.price,
        "duration": loaderData.trip_builder[1].originFlight.duration,
        "departureTime": loaderData.trip_builder[1].originFlight.departureTime,
        "arrivalTime": loaderData.trip_builder[1].originFlight.arrivalTime,

      }
      let destinationFlight2 = {
        "date": loaderData.trip_builder[1].destinationFlight.date,
        "timestamp": loaderData.trip_builder[1].destinationFlight.timestamp,
        "origin": loaderData.trip_builder[1].destinationFlight.origin,
        "destination": loaderData.trip_builder[1].destinationFlight.destination,
        "price": loaderData.trip_builder[1].destinationFlight.price,
        "duration": loaderData.trip_builder[1].destinationFlight.duration,
        "departureTime": loaderData.trip_builder[1].destinationFlight.departureTime,
        "arrivalTime": loaderData.trip_builder[1].destinationFlight.arrivalTime,
      }

      trip2 = JSON.stringify({ "originFlight": originFlight2, "destinationFlight": destinationFlight2 })

      let originFlight1 = {
        "date": loaderData.trip_builder[0].originFlight.date,
        "timestamp": loaderData.trip_builder[0].originFlight.timestamp,
        "origin": loaderData.trip_builder[0].originFlight.origin,
        "destination": loaderData.trip_builder[0].originFlight.destination,
        "price": loaderData.trip_builder[0].originFlight.price,
        "duration": loaderData.trip_builder[0].originFlight.duration,
        "departureTime": loaderData.trip_builder[0].originFlight.departureTime,
        "arrivalTime": loaderData.trip_builder[0].originFlight.arrivalTime,
      }
      let destinationFlight1 = {
        "date": loaderData.trip_builder[0].destinationFlight.date,
        "timestamp": loaderData.trip_builder[0].destinationFlight.timestamp,
        "origin": loaderData.trip_builder[0].destinationFlight.origin,
        "destination": loaderData.trip_builder[0].destinationFlight.destination,
        "price": loaderData.trip_builder[0].destinationFlight.price,
        "duration": loaderData.trip_builder[0].destinationFlight.duration,
        "departureTime": loaderData.trip_builder[0].destinationFlight.departureTime,
        "arrivalTime": loaderData.trip_builder[0].destinationFlight.arrivalTime,
      }

      trip1 = JSON.stringify({ "originFlight": originFlight1, "destinationFlight": destinationFlight1 })
    }
  }
  else {
    if (typeof document !== "undefined") {
      trip_builder = tripBuilderGetAndInflateWithoutCookie(document)
      trip_builder = tripBuilderInflate(trip_builder)


      if (trip_builder.length) {
        trip1 = trip_builder[0]
      }

      if (trip_builder.length > 1) {
        trip2 = trip_builder[1]
      }
    }
  }

  flight1 = JSON.stringify({ originFlight, destinationFlight })


  if (tripFlightMatchCheck(trip1, flight1) || tripFlightMatchCheck(trip2, flight1)) {
    disabled = true
  }

  const dateFormatted = (date, time) => {
    const parsedDate = parseISO(date);
    return `${format(parsedDate, 'MMMM d y')} at ${time}`;
  };

  const addToTripBuilder = () => {
    // Compression
    setBusy(true)
    let o = {}
    let m1 = {}
    let m2 = {}
    let d = {}

    let meta = {
      "h1": 0,
      "c1": 0,
      "a1": 0,
      "v1": 0,
      "h2": 0,
      "c2": 0,
      "a2": 0,
      "v2": 0,
      "h3": 0,
      "c3": 0,
      "a3": 0,
      "v3": 0,
      "h4": 0,
      "c4": 0,
      "a4": 0,
      "v4": 0,
    }

    flight.waypoint.meta = meta

    o = {
      "date": flight.originFlight.date,
      "t": flight.originFlight.timestamp,
      "o": flight.originFlight.origin,
      "d": flight.originFlight.destination,
      "p": flight.originFlight.price,
      "du": flight.originFlight.duration,
      "dt": flight.originFlight.departureTime,
      "at": flight.originFlight.arrivalTime,
    }

    d = {
      "date": flight.destinationFlight.date,
      "t": flight.destinationFlight.timestamp,
      "o": flight.destinationFlight.origin,
      "d": flight.destinationFlight.destination,
      "p": flight.destinationFlight.price,
      "du": flight.destinationFlight.duration,
      "dt": flight.destinationFlight.departureTime,
      "at": flight.destinationFlight.arrivalTime,
    }


    if (flight.middleFlight1) {
      m1 = {
        "date": flight.middleFlight1.date,
        "t": flight.middleFlight1.timestamp,
        "o": flight.middleFlight1.origin,
        "d": flight.middleFlight1.destination,
        "p": flight.middleFlight1.price,
        "du": flight.middleFlight1.duration,
        "dt": flight.middleFlight1.departureTime,
        "at": flight.middleFlight1.arrivalTime,
      }
    }

    if (flight.middleFlight2) {
      m2 = {
        "date": flight.middleFlight2.date,
        "t": flight.middleFlight2.timestamp,
        "o": flight.middleFlight2.origin,
        "d": flight.middleFlight2.destination,
        "p": flight.middleFlight2.price,
        "du": flight.middleFlight2.duration,
        "dt": flight.middleFlight2.departureTime,
        "at": flight.middleFlight2.arrivalTime,
      }
    }


    let compressedTrip = {
      o, d, m1, m2,
      w: flight.waypoint
    };
    tripBuilderSet(compressedTrip);
    setBusy(false)
  };

  return (
    <FlightCard className="flex flex-col items-stretch justify-start gap-4 flight-card paragraph">
      <div className="flex flex-row items-start justify-start gap-2">
        <div className="flex-1 flex flex-col items-stretch justify-start gap-2">
          <div className="flex flex-col items-stretch justify-start">
            <span className="font-bold route-info">
              <span className="content">{flight.direct ? 'Direct Route' : 'Waypoint Route'}:&nbsp;&nbsp;</span>
              <span className="route-inner">
                {flight.waypoint.route.split('-').map((code, index, array) => (
                  <span className="route" key={`${uuid}-${index}`}>
                    <AirportLink code={code} airports={airports} status={status} />
                    <span className="text-neutral-400 content">{index < array.length - 1 ? '-' : ''}</span>
                  </span>
                ))}
              </span>
            </span>
            {flight.middleFlight1 && (
              <>
                <h4 className="italic text-neutral-400 content thin">Depart: {dateFormatted(flight.originFlight.date, flight.originFlight.departureTime)}</h4>
                <h4 className="italic text-neutral-400 content thin">Second Flight: {dateFormatted(flight.middleFlight1.date, flight.middleFlight1.departureTime)}</h4>
                {flight.middleFlight2 && (
                  <>
                    <h4 className="italic text-neutral-400 content thin">Third Flight: {dateFormatted(flight.middleFlight2.date, flight.middleFlight2.departureTime)}</h4>
                  </>
                )}
                <h4 className="italic text-neutral-400 content thin">Arrive: {dateFormatted(flight.destinationFlight.date, flight.destinationFlight.arrivalTime)}</h4>
              </>
            )}
            {!flight.middleFlight1 && (
              <>
                <h4 className="italic text-neutral-400 content thin">Depart: {dateFormatted(flight.originFlight.date, flight.originFlight.departureTime)}</h4>
                <h4 className="italic text-neutral-400 content thin">Arrive: {dateFormatted(flight.destinationFlight.date, flight.destinationFlight.arrivalTime)}</h4>
              </>
            )}
            <h5 className="font-bold text-2xl content total-cost">Total Cost: <span className="bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 ">${parseFloat(flight.waypoint.price).toFixed(2)}</span></h5>
          </div>
        </div>
        <div className="button-group flex-1 flex flex-col items-stretch justify-end gap-2">
          <Button
            as="a"
            href={`https://www.google.com/search?q=direct+one+way+flight+from+${flight.originFlight.origin}+to+${flight.originFlight.destination}+on+${encodeURIComponent(dateFormatted(flight.originFlight.date, flight.originFlight.departureTime))}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row items-end justify-center gap-2"
          >
            Flight: {flight.originFlight.origin} - {flight.originFlight.destination}
            <External />
          </Button>
          {flight.middleFlight1 && (
            <Button
              as="a"
              href={`https://www.google.com/search?q=direct+one+way+flight+from+${flight.middleFlight1.origin}+to+${flight.middleFlight1.destination}+on+${encodeURIComponent(dateFormatted(flight.middleFlight1.date, flight.middleFlight1.departureTime))}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row items-end justify-center gap-2"
            >
              Flight: {flight.middleFlight1.origin} - {flight.middleFlight1.destination}
              <External />
            </Button>
          )}
          {flight.middleFlight2 && (
            <Button
              as="a"
              href={`https://www.google.com/search?q=direct+one+way+flight+from+${flight.middleFlight2.origin}+to+${flight.middleFlight2.destination}+on+${encodeURIComponent(dateFormatted(flight.middleFlight2.date, flight.middleFlight2.departureTime))}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row items-end justify-center gap-2"
            >
              Flight: {flight.middleFlight2.origin} - {flight.middleFlight2.destination}
              <External />
            </Button>
          )}
          <Button
            as="a"
            href={`https://www.google.com/search?q=direct+one+way+flight+from+${flight.destinationFlight.origin}+to+${flight.destinationFlight.destination}+on+${encodeURIComponent(dateFormatted(flight.destinationFlight.date, flight.destinationFlight.arrivalTime))}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row items-end justify-center gap-2"
          >
            Flight: {flight.destinationFlight.origin} - {flight.destinationFlight.destination}
            <External />
          </Button>
          {disabled && (
            <Button
              className="green add-trip-builder flex flex-row items-end justify-center gap-2"
              onClick={addToTripBuilder}
            >
              Trip Added
            </Button>
          )}
          {!disabled && (
            <Button
              className="yellow add-trip-builder flex flex-row items-end justify-center gap-2"
              onClick={addToTripBuilder}
            >
              Add to Trip Builder +
            </Button>
          )}
          <InformationButton uuid={uuid} route={flight.waypoint.route} status={status} airports={airports} />
        </div>
      </div>
    </FlightCard>
  );
}






export function FlightCardWrapper({ key = uuidv4(), className = "", children, ...otherProps }) {
  return (
    <div key={key} className={["flex flex-col items-stretch justify-start gap-0 border-y border-dashed border-neutral-800 divide-y divide-dashed divide-neutral-800", className].join(" ")} {...otherProps}>
      {children}
    </div>
  );
}

export function FlightCard({ key = uuidv4(), className = "", children, ...otherProps }) {
  return (
    <article key={key} className={["p-4 flight-card-inner", className].join(" ")} {...otherProps}>
      {children}
    </article>
  );
}
























