import React, { useRef, useState, useEffect } from 'react';

import * as THREE from 'three';
import { GLTFLoader } from '../../utils/GLTFLoader.js';


export default function Plane({ originAirport, destinationAirport, animated, direction, distance, showcase }) {

  const markerRef = useRef();
  const planeRef = useRef();
  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const loader = new GLTFLoader();

    if (markerRef.current) {

      loader.load(
        'marker.glb',
        (gltf) => {
          const marker = gltf.scene;

          marker.position.set(0, 0, 0);

          if (animated) {
            marker.scale.set(.0000001, .0000001, .0000001);
          } else {
            marker.scale.set(2, 2, 2);
          }

          const startingPosition = new THREE.VectorKeyframeTrack('.position', [0, 1, 2, 3, 4], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
          const middlePosition = new THREE.VectorKeyframeTrack('.position', [4, 5, 6, 7, 8], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
          const endPosition = new THREE.VectorKeyframeTrack('.position', [8, 9, 10, 11, 12], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

          const startingRotation = new THREE.QuaternionKeyframeTrack('.quaternion', [0, 1, 2, 3, 4], [
            1, 0, 0, 1,
            1, 0, 0, 0,
            1, 0, 0, 0,
            1, 0, 0, 0,
            1, 0, 0, 0,
          ]);

          const middleRotation = new THREE.QuaternionKeyframeTrack('.quaternion', [4, 5, 6, 7, 8], [
            0, 0, 0, 0,
            1, 0, 0, 0,
            1, 0, 0, 0,
            1, 0, 0, 0,
            1, 0, 0, 0,
          ]);

          const endRotation = new THREE.QuaternionKeyframeTrack('.quaternion', [8, 9, 10, 11, 12], [
            0, 0, 0, 0,
            1, 0, 0, 0,
            1, 0, 0, 0,
            1, 0, 0, 0,
            1, 0, 0, 0,
          ]);

          scene.add(marker);

          // Add lights
          const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
          directionalLight1.position.set(-3, -3, -3).normalize();
          directionalLight1.castShadow = true;  // Enable casting shadows
          scene.add(directionalLight1);

          const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1);
          directionalLight2.position.set(101, 101, 110).normalize();
          directionalLight2.castShadow = true;  // Enable casting shadows
          scene.add(directionalLight2);



          camera.position.z = 100;

          const renderer = new THREE.WebGLRenderer({ canvas: markerRef.current, alpha: true });
          // renderer.setClearColor(0xffffff, 0);
          renderer.setSize(window.innerWidth, window.innerHeight);
          renderer.shadowMap.enabled = true;  // Enable shadow maps

          const startingClip = new THREE.AnimationClip('starting', 12, [startingPosition, middlePosition, endPosition, startingRotation, middleRotation, endRotation]);

          const mixer = new THREE.AnimationMixer(marker);
          const startingAction = mixer.clipAction(startingClip);
          startingAction.setLoop(THREE.LoopOnce);
          startingAction.clampWhenFinished = true;

          function animate() {
            requestAnimationFrame(animate);
            const delta = 0.0000001;
            mixer.update(delta);
            renderer.render(scene, camera);
          }

          startingAction.play();
          animate();
        },
        undefined,
        (error) => {
          console.error(error);
        }
      );
    }
  }, [animated]);



  useEffect(() => {

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const loader = new GLTFLoader();


    if (planeRef.current) {


      const textureLoader = new THREE.TextureLoader();



      loader.load(
        'airplane.glb',
        (gltf) => {
          const airplane = gltf.scene;

          const texture = textureLoader.load('airplane_texture.jpg?v=' + new Date().getTime());
          const material = new THREE.MeshPhongMaterial({
            map: texture,
            shininess: 100,
            specular: new THREE.Color(0xaaaaaa),
          });

          airplane.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              child.material = material;
            }
          });

          let takeoffPosition = {}
          let flyPosition = {}
          let landPosition = {}
          let takeoffRotation = {}
          let flyRotation = {}
          let landRotation = {}

          if (!animated) {
            console.log("showcase", { showcase, direction })
            if (showcase === true) {
              airplane.scale.set(.03, .03, .03);
            }
            else {
              airplane.scale.set(.03, .03, .03);
            }
            airplane.position.set(50, 0, -500);

            takeoffPosition = new THREE.VectorKeyframeTrack('.position', [0, 1, 2, 3, 4], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
            flyPosition = new THREE.VectorKeyframeTrack('.position', [4, 5, 6, 7, 8], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
            landPosition = new THREE.VectorKeyframeTrack('.position', [8, 9, 10, 11, 12], [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);


            takeoffRotation = new THREE.QuaternionKeyframeTrack('.quaternion', [0, 1, 2, 3, 4], [
              0, 0, 0, 1,
              0, 0 - .75, 0, 1,
              0, 0 - 1.5, 0, 1,
              0, 0, 0, 1,
              0, direction, 0, 1,
            ]);

            flyRotation = new THREE.QuaternionKeyframeTrack('.quaternion', [4, 5, 6, 7, 8], [
              0, 0, 0, 1,
              0, direction, 0, 1,
              0, 0, 0, 1,
              0, direction, 0, 1,
              0, 0, 0, 1,
            ]);

            landRotation = new THREE.QuaternionKeyframeTrack('.quaternion', [8, 9, 10, 11, 12], [
              0, direction, 0, 1,
              0, 0, 0, 1,
              0, 0 - 1.5, 0, 1,
              0, 0 - .75, 0, 1,
              0, 0, 0, 1,
            ]);

          }
          else {
            airplane.scale.set(.19, .19, .19);

            airplane.position.set(50, 0, -500);

            takeoffPosition = new THREE.VectorKeyframeTrack('.position', [0, 1, 2, 3, 4], [50, 0, -500, 200, 50, -500, 150, 0, -400, 100, 0, -200, 100, 0, -100]);
            flyPosition = new THREE.VectorKeyframeTrack('.position', [4, 5, 6, 7, 8], [-50, -5, -75, -50, -2, -50, -50, -2, -50, -50, -1, -2, 0, -6, 0]);
            landPosition = new THREE.VectorKeyframeTrack('.position', [8, 9, 10, 11, 12], [-50, 0, 0, -50, 0, 0, -50, 0, 0, -50, 0, -250, -50, 0, -500]);

            takeoffRotation = new THREE.QuaternionKeyframeTrack('.quaternion', [0, 1, 2, 3, 4], [
              0, -Math.sin(Math.PI / 2), Math.sin(Math.PI / 2), 1,
              0, -Math.sin(Math.PI / 2), Math.sin(Math.PI / 3), 1,
              Math.sin(Math.PI / 3), -Math.sin(Math.PI / 2), Math.sin(Math.PI / 2), 1,
              -Math.sin(Math.PI / 2), -Math.sin(Math.PI / 2), 0, 1,
              Math.sin(Math.PI / 1), 0, 0, 1,

            ]);

            flyRotation = new THREE.QuaternionKeyframeTrack('.quaternion', [4, 5, 6, 7, 8], [
              Math.sin(Math.PI / 2), -Math.sin(Math.PI / 2), 0, 1,
              Math.sin(Math.PI / -2), Math.sin(direction), 0, 0,
              0, -Math.sin(direction), 0, 1,
              Math.sin(Math.PI / 2), -Math.sin(direction), 0, 1,
              Math.sin(Math.PI / -2), -Math.sin(direction), 0, 1,

            ]);

            landRotation = new THREE.QuaternionKeyframeTrack('.quaternion', [8, 9, 10, 11, 12], [
              0, Math.sin(Math.PI / 2), 0, 1,
              Math.sin(Math.PI / 3), Math.sin(Math.PI / 2), 0, 1,
              Math.sin(Math.PI / -2), Math.sin(Math.PI / 2), 0, 1,
              0, 1, 0, 1,
              Math.sin(Math.PI / 12), 2, 0, 1,

            ]);

          }


          scene.add(airplane);

          const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
          directionalLight.position.set(0, 10, 10);
          scene.add(directionalLight);

          const ambientLight = new THREE.AmbientLight(0x404040, 1);
          scene.add(ambientLight);

          camera.position.z = 25;

          const renderer = new THREE.WebGLRenderer({ canvas: planeRef.current, alpha: true });
          renderer.setClearColor(0x000000, 0);
          renderer.setSize(window.innerWidth, window.innerHeight);



          const takeoffClip = new THREE.AnimationClip('Takeoff', 12, [takeoffPosition, flyPosition, landPosition, takeoffRotation, flyRotation, landRotation]);

          const mixer = new THREE.AnimationMixer(airplane);
          const takeoffAction = mixer.clipAction(takeoffClip);
          if (!animated) {
            takeoffAction.setLoop(THREE.LoopRepeat, 9);
          }
          else {
            takeoffAction.setLoop(THREE.LoopOnce);
          }

          takeoffAction.clampWhenFinished = true;

          // Inside animate function
          function animate() {
            requestAnimationFrame(animate);
            const delta = 0.01;
            mixer.update(delta);
            renderer.render(scene, camera);

          }


          takeoffAction.play();
          animate();
        },
        undefined,
        (error) => {
          console.error(error);
        }
      );









    }
  }, [animated]);















  return (
    <>
      <canvas className="plane fade-in-slow" ref={planeRef} />
      {/* <canvas className="marker fade-in-very-slow" ref={markerRef} /> */}
    </>

  );
}
