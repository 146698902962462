import { useState, useEffect } from "react";

import { v4 as uuidv4 } from 'uuid';


export default function AirportLink({ code, status, airports }) {
  if (!Array.isArray(airports)) {
    return null;
  }
  const googleMapsLink = `https://www.google.com/maps/search/${code}+airport`;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    const handleTouchOutside = (event) => {
      if (!event.target.closest('.airport-link-wrapper')) {
        setIsTooltipVisible(false);
      }
    };

    document.body.addEventListener('touchstart', handleTouchOutside);

    return () => {
      document.body.removeEventListener('touchstart', handleTouchOutside);
    };
  }, []);

  const handleTooltipVisibility = (value) => {
    setIsTooltipVisible(value);
  };

  const airport = airports.find(airport => airport.code === code) || {};
  const airportMeta = airport.meta || {};

  return (
    <span
      className="airport-link-wrapper"
      onMouseEnter={() => handleTooltipVisibility(true)}
      onTouchStart={() => handleTooltipVisibility(true)}
      onMouseLeave={() => handleTooltipVisibility(false)}
    >
      <span className="code bg-clip-text bg-gradient-to-br from-pink-400 via-blue-400 to-blue-600 text-transparent">{code}</span>
      {isTooltipVisible && (
        <div className="code-tooltip pulse">
          <div className="text-group">
            <div className="title-group">
              <a className="airport-name italic text-neutral-400" href={googleMapsLink} target="_blank" rel="noopener noreferrer">{airport.name}</a>
              <a className="font-medium text-xs uppercase" href={googleMapsLink} target="_blank" rel="noopener noreferrer">{airport.country}</a>
              <div className="meta danger font-medium text-xs uppercase"><span>Danger Rating: {airportMeta.danger || 'Unknown'}</span></div>
            </div>
            <div className="button-group">
              <a className="whitespace-nowrap map-link font-medium text-xs uppercase text-white text-center border border-opacity-50 px-3 py-1 rounded-md block border-blue-400 bg-blue-500 hover:border-blue-300" href={googleMapsLink} target="_blank" rel="noopener noreferrer">Map</a>
              <span onClick={(event) => onRemove(event, status)} data-code={code} className="whitespace-nowrap close-tooltip font-medium text-xs uppercase text-center text-white border border-opacity-50 px-3 py-1 rounded-md border-red-400 bg-red-500 hover:border-red-300 disabled:cursor-not-allowed disabled:opacity-60 disabled:border-transparent disabled:hover:border-transparent focus:outline-none focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-blue-500 flex-1">{status === 2 ? 'Exclude' : 'Exclude 🔒'}</span>
            </div>
          </div>
        </div>
      )}
    </span>
  );
}

const onRemove = (event, status) => {
  if (status === 2) {
    const excludeField = document.getElementById('exclude');
    const code = event.target.dataset.code;

    if (excludeField.value.trim() !== "") {
      excludeField.value += `${code},`;
    } else {
      excludeField.value = `${code},`;
    }

    document.getElementById('search').click();
  }
};





















